<template>
  <AppSubmenu class="layout-menu" :items="menu" :layoutMode="layoutMode" :menuActive="active" :mobileMenuActive="mobileMenuActive" :root="true" :parentMenuItemActive="true" />
</template>

<script>
import AppSubmenu from '@/components/setup/AppSubmenu';
import { AuthControllers } from '@/controllers/auth/AuthControllers';
import EventBus from '@/setting/event-bus';
import { store } from '@/store/index.js';

export default {
  data: () => ({
    auth: new AuthControllers(),
    menu: [{ label: 'Loading Menu', icon: 'pi pi-fw pi-home' }],
  }),
  props: {
    model: Array,
    layoutMode: String,
    active: Boolean,
    mobileMenuActive: Boolean,
  },
	created() {
    if (store.state.isAuthenticated) {
      this.loadMenu();
    }
  },
  mounted() {
    EventBus.on('show-menu-panel', () => {
      this.loadMenu();
    });
  },
  methods: {
    async loadMenu() {
      let menus = [];
      if (store.state.isAuthenticated) {
        menus = await this.auth.loadMenu();
      }
      if (this.menu.length < 1) {
        this.menu = [{ label: 'Menu Not Found', icon: 'pi pi-fw pi-home' }];
      } else {
        this.menu = [{ label: 'Menus', icon: 'pi pi-fw pi-home', items: menus }];
      }
    },
  },
  components: {
    AppSubmenu: AppSubmenu,
  },
};
</script>
