import { mapDestinationRequest } from '@/api/request/auth/AuthRequest';
import { searchMasterDefaultRequest, lookupMasterDefaultRequest, dataMasterDefaultRequest, saveMasterDefaultRequest, updateMasterDefaultRequest, deleteMasterDefaultRequest, authMasterPrintRequest } from '@/api/request/master/MasterRequest';
import { checkErrorResponse } from '@/store';

export class MasterControllers {
  mapDestination(data) {
    let resp = null;
    resp = mapDestinationRequest(data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }

  searchMaster(module, data) {
    let resp = null;
    resp = searchMasterDefaultRequest(module, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }

  lookupMaster(module, data) {
    let resp = null;
    resp = lookupMasterDefaultRequest(module, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }

  dataMaster(module, data) {
    let resp = null;
    resp = dataMasterDefaultRequest(module, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }

  saveMaster(module, data) {
    let resp = null;
    resp = saveMasterDefaultRequest(module, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }

  updateMaster(module, data) {
    let resp = null;
    resp = updateMasterDefaultRequest(module, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }

  deleteMaster(module, data) {
    let resp = null;
    resp = deleteMasterDefaultRequest(module, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }

  printMaster(module, data) {
    let resp = null;
    resp = authMasterPrintRequest(module, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }
}
