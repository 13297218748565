import axios from 'axios'
import {
  searchSettingDefault,
  lookupSettingDefault,
  saveSettingDefault,
  dataSettingDefault,
  updateSettingDefault,
  deleteSettingDefault } from '@/api/endpoint/setting/SettingEndpoint';
import { BaseSettingUrl } from '@/api/endpoint/base'
import { store } from "@/store/index.js"

export const searchSettingDefaultRequest = (module, data) => {
  let resp = null
  resp = axios({
    method: 'GET',
    url: `${BaseSettingUrl()}${searchSettingDefault(module,data)}`,
    headers: {
      'Authorization': `Bearer ${store.state.token}`,
    }
  }).then((response) => {
    return response
  })
  return resp
}

export const lookupSettingDefaultRequest = (module, path, data) => {
  let resp = null
  resp = axios({
    method: 'GET',
    url: `${BaseSettingUrl()}${lookupSettingDefault(module, path, data)}`,
    headers: {
      'Authorization': `Bearer ${store.state.token}`,
    }
  }).then((response) => {
    return response
  })
  return resp
}

export const dataSettingDefaultRequest = (module, data) => {
  let resp = null
  resp = axios({
    method: 'GET',
    url: `${BaseSettingUrl()}${dataSettingDefault(module,data)}`,
    headers: {
      'Authorization': `Bearer ${store.state.token}`,
    }
  }).then((response) => {
    return response
  })
  return resp
}

export const saveSettingDefaultRequest = (module,data) => {
  let resp = null
  resp = axios({
    method: 'POST',
    data: data,
    url: `${BaseSettingUrl()}${saveSettingDefault(module)}`,
    headers: {
      'Authorization': `Bearer ${store.state.token}`,
    }
  }).then((response) => {
    return response
  })
  return resp
}

export const updateSettingDefaultRequest = (module,data) => {
  let resp = null
  resp = axios({
    method: 'POST',
    data: data,
    url: `${BaseSettingUrl()}${updateSettingDefault(module)}`,
    headers: {
      'Authorization': `Bearer ${store.state.token}`,
    }
  }).then((response) => {
    return response
  })
  return resp
}

export const deleteSettingDefaultRequest = (module,data) => {
  let resp = null
  resp = axios({
    method: 'POST',
    url: `${BaseSettingUrl()}${deleteSettingDefault(module,data)}`,
    headers: {
      'Authorization': `Bearer ${store.state.token}`,
    }
  }).then((response) => {
    return response
  })
  return resp
}
