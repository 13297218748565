import moment from 'moment';
import { store } from '@/store';
export const formatOjectDateTime = (obj, list) => {
  let field = {};
  for (const property in obj) {
    if (list.includes(property)) {
      field[property] = moment(obj[property], 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    } else {
      field[property] = obj[property];
    }
  }
  return field;
};

export const authThorization = (nameAuth) => {
  if (!getParam('cypr')) {
    return true;
  }

  let auth = JSON.parse(atob(getParam('cypr')));
  let resp = 0;
  
  if (nameAuth == 'assign') {
    resp = auth.assign;
    if(store.state.isAuthPeriod){
      return false;
    }
  }
  if (nameAuth == 'save') {
    resp = auth.save;
    if(store.state.isAuthPeriod){
      return false;
    }
  }
  if (nameAuth == 'update') {
    resp = auth.update;
    if(store.state.isAuthPeriod){
      return false;
    }
  }
  if (nameAuth == 'delete') {
    resp = auth.delete;
    if(store.state.isAuthPeriod){
      return false;
    }
  }
  if (nameAuth == 'print') {
    resp = auth.print;
  }
  if(resp == 1){
    return true;
  }else{
    return false;
  }
};

export const formatOjectDate = (obj, list) => {
  let field = {};
  for (const property in obj) {
    if (list.includes(property)) {
      field[property] = moment(obj[property], 'DD/MM/YYYY').format('YYYY-MM-DD');
    } else {
      field[property] = obj[property];
    }
  }
  return field;
};

export const regexRemove = (array, value) => {
  return regexReplace(array, value, '');
};

export const formatingElementsNumberBy = (arr, arrs) => {
  let list = [];
  arr.forEach(function (objx) {
    arrs.forEach(function (objy) {
      objx[objy] = decimalCurrency(objx[objy]);
    });
    list.push(objx);
  });
  return list;
};

export const uniqueElementsBy = (arr, fn) =>
  arr.reduce((obj, v) => {
    if (!obj.some((x) => fn(v, x))) obj.push(v);
    return obj;
  }, []);

export const removeElementsBy = (arr, obj, fn) => arr.filter((x) => fn(x, obj), []);

export const removeElementsFor = (arr, arrs, fn) => {
  let list = [];
  arr.forEach(function (x) {
    let i = true;
    arrs.forEach(function (y) {
      if (fn(x, y) && i) {
        list.push(x);
        i = false;
      }
    });
  });
  return list;
};

export const roundedElementsBy = (arr, arrs, fn) => {
  let list = [];
  arr.forEach(function (objx) {
    arrs.forEach(function (objy) {
      if (fn(objx)) {
        objx[objy] = Math.round(objx[objy]);
      }
    });
    list.push(objx);
  });
  return list;
};

export const roundedFieldBy = (val, curr) => {
  if (curr == 'IDR' || curr == '') {
    val = Math.round(val);
  }
  return val;
};

export const regexReplace = (array, value, perfix) => {
  let response = value;
  for (var i = 0; i < array.length; i++) {
    response = response.replaceAll(array[i], perfix);
  }
  return response;
};

export const valdNull = (val, re) => {
  return val == null ? re : val;
};

export const removeCommas = (val) => {
  return String(val).replace(/,/g, '');
};

export const decimalCurrency = (val) => {
  let num = removeCommas(val);
  return parseFloat(num)
    ?.toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
};

export const formatPrice = (price) => {
  if (!price) return 'Rp. 0';
  const result = price.toLocaleString('id');
  return `Rp. ${result}`;
};

export const getStatus = (val, is) => {
  if (is) {
    return parseInt(val) == 1 || val == true ? 'Active' : 'In Active';
  } else {
    return parseInt(val) == 1 || val == true ? 'success' : 'warning';
  }
};

export const getParam = (p) => {
  let param = new URLSearchParams(window.location.href.substr(window.location.href.indexOf('?')));
  if (param.has(p)) {
    return param.get(p);
  } else {
    return null;
  }
};
