const reportsSales = [
  //GROUP SALES
  {
    path: '/reports/sales/abc-analysis',
    name: 'reports abc-analysis',
    component: () => import('@/views/reports/sales/abc-analysis.vue'),
  },
  {
    path: '/reports/sales/abc-analysis-per-category',
    name: 'reports abc-analysis-per-category',
    component: () => import('@/views/reports/sales/abc-analysis-per-category.vue'),
  },
  {
    path: '/reports/sales/abc-analysis-per-sub-category',
    name: 'reports abc-analysis-per-sub-category',
    component: () => import('@/views/reports/sales/abc-analysis-per-sub-category.vue'),
  },
  {
    path: '/reports/sales/sales-order',
    name: 'reports sales-order',
    component: () => import('@/views/reports/sales/sales-order.vue'),
  },
  {
    path: '/reports/sales/sales-order-per-customer',
    name: 'reports sales-order-per-customer',
    component: () => import('@/views/reports/sales/sales-order-per-customer.vue'),
  },
  {
    path: '/reports/sales/sales-order-per-sales-person',
    name: 'reports sales-order-per-sales-person',
    component: () => import('@/views/reports/sales/sales-order-per-sales-person.vue'),
  },
  {
    path: '/reports/sales/sales-order-per-item',
    name: 'reports sales-order-per-item',
    component: () => import('@/views/reports/sales/sales-order-per-item.vue'),
  },
  {
    path: '/reports/sales/sales-order-realization',
    name: 'reports sales-order-realization',
    component: () => import('@/views/reports/sales/sales-order-realization.vue'),
  },
  {
    path: '/reports/sales/outstanding-sales-order',
    name: 'reports outstanding-sales-order',
    component: () => import('@/views/reports/sales/outstanding-sales-order.vue'),
  },
  {
    path: '/reports/sales/outstanding-sales-order-per-customer',
    name: 'reports outstanding-sales-order-per-customer',
    component: () => import('@/views/reports/sales/outstanding-sales-order-per-customer.vue'),
  },
  {
    path: '/reports/sales/outstanding-sales-order-per-sales-person',
    name: 'reports outstanding-sales-order-per-sales-person',
    component: () => import('@/views/reports/sales/outstanding-sales-order-per-sales-person.vue'),
  },
  {
    path: '/reports/sales/outstanding-sales-order-per-item',
    name: 'reports outstanding-sales-order-per-item',
    component: () => import('@/views/reports/sales/outstanding-sales-order-per-item.vue'),
  },
  {
    path: '/reports/sales/sales-order-material-form-sample',
    name: 'reports sales-order-material-form-sample',
    component: () => import('@/views/reports/sales/sales-order-material-form-sample.vue'),
  },
  {
    path: '/reports/sales/sales-non-cogs-idr',
    name: 'reports sales-non-cogs-idr',
    component: () => import('@/views/reports/sales/sales-non-cogs-idr.vue'),
  },
  {
    path: '/reports/sales/sales-per-customer-non-cogs-idr',
    name: 'reports sales-per-customer-non-cogs-idr',
    component: () => import('@/views/reports/sales/sales-per-customer-non-cogs-idr.vue'),
  },
  {
    path: '/reports/sales/sales-per-sales-person-non-cogs-idr',
    name: 'reports sales-per-sales-person-non-cogs-idr',
    component: () => import('@/views/reports/sales/sales-per-sales-person-non-cogs-idr.vue'),
  },
  {
    path: '/reports/sales/sales-per-sales-person-non',
    name: 'reports sales-per-sales-person-non',
    component: () => import('@/views/reports/sales/sales-per-sales-person-non.vue'),
  },
  {
    path: '/reports/sales/sales-by-cogs-idr',
    name: 'reports sales-by-cogs-idr',
    component: () => import('@/views/reports/sales/sales-by-cogs-idr.vue'),
  },
  {
    path: '/reports/sales/sales-per-customer-by-cogs-idr',
    name: 'reports sales-per-customer-by-cogs-idr',
    component: () => import('@/views/reports/sales/sales-per-customer-by-cogs-idr.vue'),
  },
  {
    path: '/reports/sales/sales-per-sales-person-by-cogs-idr',
    name: 'reports sales-per-sales-person-by-cogs-idr',
    component: () => import('@/views/reports/sales/sales-per-sales-person-by-cogs-idr.vue'),
  },
  {
    path: '/reports/sales/sales-per-item-by-cogs-idr',
    name: 'reports sales-per-item-by-cogs-idr',
    component: () => import('@/views/reports/sales/sales-per-item-by-cogs-idr.vue'),
  },
  {
    path: '/reports/sales/sales-return',
    name: 'reports sales-return',
    component: () => import('@/views/reports/sales/sales-return.vue'),
  },
  {
    path: '/reports/sales/sales-return-per-customer',
    name: 'reports sales-return-per-customer',
    component: () => import('@/views/reports/sales/sales-return-per-customer.vue'),
  },
  {
    path: '/reports/sales/sales-return-per-sales-person',
    name: 'reports sales-return-per-sales-person',
    component: () => import('@/views/reports/sales/sales-return-per-sales-person.vue'),
  },
  {
    path: '/reports/sales/sales-return-per-item',
    name: 'reports sales-return-per-item',
    component: () => import('@/views/reports/sales/sales-return-per-item.vue'),
  },
  {
    path: '/reports/sales/sales-invoice-recapitulation',
    name: 'reports sales-invoice-recapitulation',
    component: () => import('@/views/reports/sales/sales-invoice-recapitulation.vue'),
  },
  {
    path: '/reports/sales/sales-invoice-recapitulation-global',
    name: 'reports sales-invoice-recapitulation-global',
    component: () => import('@/views/reports/sales/sales-invoice-recapitulation-global.vue'),
  },
  {
    path: '/reports/sales/sales-invoice-recapitulation-per-customer',
    name: 'reports sales-invoice-recapitulation-per-customer',
    component: () => import('@/views/reports/sales/sales-invoice-recapitulation-per-customer.vue'),
  },
  {
    path: '/reports/sales/sales-recapitulation-per-sales-person',
    name: 'reports sales-recapitulation-per-sales-person',
    component: () => import('@/views/reports/sales/sales-recapitulation-per-sales-person.vue'),
  },
  {
    path: '/reports/sales/sales-recapitulation-per-item',
    name: 'reports sales-recapitulation-per-item',
    component: () => import('@/views/reports/sales/sales-recapitulation-per-item.vue'),
  },
  {
    path: '/reports/sales/customer-credit-note',
    name: 'reports customer-credit-note',
    component: () => import('@/views/reports/sales/customer-credit-note.vue'),
  },
  {
    path: '/reports/sales/customer-credit-note-per-customer',
    name: 'reports customer-credit-note-per-customer',
    component: () => import('@/views/reports/sales/customer-credit-note-per-customer.vue'),
  },
  {
    path: '/reports/sales/customer-debit-note',
    name: 'reports customer-debit-note',
    component: () => import('@/views/reports/sales/customer-debit-note.vue'),
  },
  {
    path: '/reports/sales/customer-debit-note-per-customer',
    name: 'reports customer-debit-note-per-customer',
    component: () => import('@/views/reports/sales/customer-debit-note-per-customer.vue'),
  },
  {
    path: '/reports/sales/sales-invoice-detail-by-project',
    name: 'reports sales-invoice-detail-by-project',
    component: () => import('@/views/reports/sales/sales-invoice-detail-by-project.vue'),
  },
  {
    path: '/reports/sales/sales-invoice-detail',
    name: 'reports sales-invoice-detail',
    component: () => import('@/views/reports/sales/sales-invoice-detail.vue'),
  },
  {
    path: '/reports/sales/sales-invoice-detail-by-category',
    name: 'reports sales-invoice-detail-by-category',
    component: () => import('@/views/reports/sales/sales-invoice-detail-by-category.vue'),
  },
  {
    path: '/reports/sales/sales-invoice-detail-by-sales-person',
    name: 'reports sales-invoice-detail-by-sales-person',
    component: () => import('@/views/reports/sales/sales-invoice-detail-by-sales-person.vue'),
  },
];

export default reportsSales;
