export const BaseAuthUrl = () => process.env.APP_IKB_API_AUTH_URL
export const BaseSettingUrl = () => process.env.APP_IKB_API_SETTING_URL
export const BaseReportUrl = () => process.env.APP_IKB_API_REPORT_URL
export const BaseMasterUrl = () => process.env.APP_IKB_API_MASTER_URL
export const BaseSalesUrl = () => process.env.APP_IKB_API_SALES_URL
export const BaseFinanceUrl = () => process.env.APP_IKB_API_FINANCE_URL
export const BasePurchaseUrl = () => process.env.APP_IKB_API_PURCHASE_URL
export const BaseWarehouseUrl = () => process.env.APP_IKB_API_WAREHOUSE_URL
export const BaseAccountingUrl = () => process.env.APP_IKB_API_ACCOUNTING_URL

export const BasePrintReportUrl = () => process.env.APP_IKB_API_REPORT_URL
export const BasePrintMasterUrl = () => process.env.APP_IKB_API_REPORT_MASTER_URL
export const BasePrintSalesUrl = () => process.env.APP_IKB_API_REPORT_SALES_URL
export const BasePrintFinanceUrl = () => process.env.APP_IKB_API_REPORT_FINANCE_URL
export const BasePrintPurchaseUrl = () => process.env.APP_IKB_API_REPORT_PURCHASE_URL
export const BasePrintWarehouseUrl = () => process.env.APP_IKB_API_REPORT_WAREHOUSE_URL
export const BasePrintAccountingUrl = () => process.env.APP_IKB_API_REPORT_ACCOUNTING_URL
