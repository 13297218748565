<template>
	<div class="layout-topbar">
		<!-- <button class="p-link layout-right-panel-button layout-topbar-icon" id="menu-button" @click="onRightMenuButtonClick">
			<i class="pi pi-ellipsis-v"></i>
		</button> -->

		<button class="p-link layout-menu-button layout-topbar-icon" @click="onMenuButtonClick">
			<i class="pi pi-bars"></i>
		</button>

		<router-link to="/" class="p-link layout-topbar-logo">
			<img id="topbar-logo" src="img/hpi.jpg" alt="roma-vue" />
			<!-- <label>BACKOFFICE</label> -->
		</router-link>

    <ul class="topbar-menu">
      <li :class="['user-profile', { 'active-topmenuitem fadeInDown': activeTopbarItem === 'profile' }]">
        <button class="p-link" v-if="!this.inlineUser" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'profile' })">
          <img src="img/logo.jpeg" alt="roma-layout" :hidden="!isDesktop()"/>
          <div class="layout-profile-userinfo">
            <span class="layout-profile-name">{{ getName() }}</span>
            <span class="layout-profile-role">{{ getRole() }}</span>
          </div>
        </button>
        <transition name="layout-submenu-container">
          <ul class="fadeInDown" v-show="activeTopbarItem === 'profile'">
            <li role="menuitem">
              <button class="p-link" @click="this.$router.push({ path: '/profile' })">
                <i class="pi pi-fw pi-user"></i>
                <span>Profile</span>
              </button>
            </li>
            <li role="menuitem">
              <button class="p-link" @click="showModal">
                <i class="pi pi-fw pi-cog"></i>
                <span>Change Password</span>
              </button>
            </li>
            <!-- <li role="menuitem">
              <button class="p-link" @click="this.$router.push({ path: '/setting' })">
                <i class="pi pi-fw pi-cog"></i>
                <span>Change Password</span>
              </button>
            </li> -->
            <li role="menuitem">
              <button class="p-link" @click="logout">
                <i class="pi pi-fw pi-sign-out"></i>
                <span>Logout</span>
              </button>
            </li>
          </ul>
        </transition>
      </li>

      <li :class="[{ 'active-topmenuitem fadeInDown': activeTopbarItem === 'settings' }]">
        <div class="mt-2">
          <Dropdown v-model="periodMonth" :options="dataMonth" :change="periodMonths()" optionValue="code" optionLabel="name" :filter="true" placeholder="Select Month" />
          &nbsp;
          <Dropdown v-model="periodYear" :options="dataYear" :change="periodYears()" optionValue="code" optionLabel="name" :filter="true" placeholder="Select Year" />
        </div>
      </li>
    </ul>
	</div>
	
  <Dialog styleClass="surface-ground" header="Change Password" v-model:visible="isChangePassword" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '40vw' }">
    <div style="display: flex; justify-content: center">
      <div class="card login-panel p-fluid col-12 md:col-12 p-0 m-0" style="background: darkgray; display: flex; justify-content: center">
        <div class="login-panel-content p-3 m-3">
          <form v-on:keyup.enter="submit">
            <div class="grid">
              <div class="p-1 m-0 col-12 username-container">
                <div class="p-inputgroup">
                  <InputText type="text" v-model="form.username" placeholder="Enter username" disabled="true" />
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-user"></i>
                  </span>
                </div>
              </div>
              <div class="p-1 m-0 col-12 password-container">
                <div class="p-inputgroup">
                  <InputText type="password" v-model="form.password" placeholder="Enter password" />
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-eye-slash"></i>
                  </span>
                </div>
              </div>
              <div class="p-1 m-0 col-12 password-container">
                <div class="p-inputgroup">
                  <InputText type="password" v-model="form.repassword" placeholder="Enter re-password" />
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-eye-slash"></i>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <template #footer>
      <Button label="change" :loading="isLoading" icon="pi pi-check" @click="submit" />
    </template>
  </Dialog>
</template>

<script>

import EventBus from '@/setting/event-bus';
import { removeAuth, setPeriodMonth, setPeriodYear, getPeriodMonth, getPeriodYear } from '@/utils/cookies';
import { dataMonths, dataYears } from '@/setting/data';
import { store } from '@/store';
import { SettingControllers } from '@/controllers/setting/SettingControllers';

export default {
	emits: ['menubutton-click', 'topbar-menubutton-click', 'topbar-item-click', 'right-menubutton-click'],
	data: () => ({
		auth: new SettingControllers(),
    periodMonth: store.state.periodMonth,
    periodYear: store.state.periodYear,
		isChangePassword: false,
		isLoading: false,
		form: {
			code: "",
			username: store.state.profile.username,
			password: "",
			repassword: "",
		},
  }),
	props: {
		topbarMenuActive: Boolean,
		activeTopbarItem: String,
		inlineUser: Boolean,
	},
  computed: {
    dataMonth() {
      return dataMonths;
    },
    dataYear() {
      return dataYears;
    },
  },
	methods: {
    logout() {
      store.state.token = null;
      removeAuth();
      this.$router.push({ path: '/login' });
    },
    getName() {
      return store.state.profile.fullName;
    },
    getEmployee() {
      return store.state.profile.employee;
    },
    getRole() {
      return store.state.profile.roleName;
    },
    periodMonths() {
      let last = getPeriodMonth();
      store.state.periodMonth = this.periodMonth;
      setPeriodMonth(this.periodMonth);
      store.dispatch('storePeriodMonth', this.periodMonth);
      if (last != this.periodMonth) {
        location.reload();
      }
    },
    periodYears() {
      let last = getPeriodYear();
      store.state.periodYear = this.periodYear;
      setPeriodYear(this.periodYear);
      store.dispatch('storePeriodYear', this.periodYear);
      if (last != this.periodYear) {
        location.reload();
      }
		},
		showModal() {
			EventBus.emit('show-locked-panel',true);
			this.form.username = store.state.profile.username;
			this.form.password = '';
			this.form.repassword = '';
			this.isChangePassword = true;
		},
		async submit() {
			let BreakException = [];
			this.form.code = this.form.username;
			try {
				if (this.form.password != this.form.repassword) {
					this.$toast.add({ severity: 'warn', summary: 'password not match!', life: 3000 });
					throw BreakException;
				}
				if (this.form.password == '' || this.form.repassword == '') {
					this.$toast.add({ severity: 'warn', summary: "password can't be empty!", life: 3000 });
					throw BreakException;
				}
				this.isLoading = true;
				await this.auth.updateSetting('user/change-password', this.form);
				this.isLoading = false;
				this.isChangePassword = false;
				EventBus.emit('show-locked-panel', false);
				this.$toast.add({ severity: 'success', summary: "update password success!", life: 3000 });
			} catch (e) {
				if (e !== BreakException) throw e;
			}
		},
		isDesktop() {
			return window.innerWidth > 896;
		},
		onMenuButtonClick(event) {
			this.$emit('menubutton-click', event);
		},
		onTopbarMenuButtonClick(event) {
			this.$emit('topbar-menubutton-click', event);
		},
		onRightMenuButtonClick(event) {
			this.$emit('right-menubutton-click', event);
		}
	}
}
</script>
