export const Login = () => {
  return `/login`
}

export const Validates = () => {
  return `/validate`
}

export const LoadMenu = () => {
  return `/menus`
}

export const LoadBranch = () => {
  return `/branch`
}

export const CheckUser = (username) => {
  return `/check/`+username
}

export const MapDestination = (data) => {
  return `/destination?`+Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&')
}
