import axios from 'axios';
import { searchMasterDefault, lookupMasterDefault, saveMasterDefault, dataMasterDefault, updateMasterDefault, deleteMasterDefault, authMasterPrint } from '@/api/endpoint/master/MasterEndpoint';
import { BaseMasterUrl, BasePrintMasterUrl } from '@/api/endpoint/base';
import { store } from '@/store/index.js';

export const searchMasterDefaultRequest = (module, data) => {
  let resp = null;
  resp = axios({
    method: 'GET',
    url: `${BaseMasterUrl()}${searchMasterDefault(module, data)}`,
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  }).then((response) => {
    return response;
  });
  return resp;
};

export const lookupMasterDefaultRequest = (module, path, data) => {
  let resp = null;
  resp = axios({
    method: 'GET',
    url: `${BaseMasterUrl()}${lookupMasterDefault(module, path, data)}`,
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  }).then((response) => {
    return response;
  });
  return resp;
};

export const dataMasterDefaultRequest = (module, data) => {
  let resp = null;
  resp = axios({
    method: 'GET',
    url: `${BaseMasterUrl()}${dataMasterDefault(module, data)}`,
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  }).then((response) => {
    return response;
  });
  return resp;
};

export const saveMasterDefaultRequest = (module, data) => {
  let resp = null;
  resp = axios({
    method: 'POST',
    data: data,
    url: `${BaseMasterUrl()}${saveMasterDefault(module)}`,
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  }).then((response) => {
    return response;
  });
  return resp;
};

export const updateMasterDefaultRequest = (module, data) => {
  let resp = null;
  resp = axios({
    method: 'POST',
    data: data,
    url: `${BaseMasterUrl()}${updateMasterDefault(module)}`,
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  }).then((response) => {
    return response;
  });
  return resp;
};

export const deleteMasterDefaultRequest = (module, data) => {
  let resp = null;
  resp = axios({
    method: 'POST',
    url: `${BaseMasterUrl()}${deleteMasterDefault(module, data)}`,
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  }).then((response) => {
    return response;
  });
  return resp;
};

export const authMasterPrintRequest = (module, data) => {
  let resp = null;
  resp = axios({
    method: 'POST',
    url: `${BasePrintMasterUrl()}${authMasterPrint(module)}`,
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
    data: data,
  }).then((response) => {
    return response;
  });
  return resp;
};
