const finance = [
  //GROUP FINANCE AP
  {
    path: '/finance/payment-request',
    name: 'finance payment request',
    component: () => import('@/views/finance/payment-request'),
  },
  {
    path: '/finance/payment-request/form',
    name: 'finance payment request form',
    component: () => import('@/views/finance/payment-request/form'),
  },
  {
    path: '/finance/payment-request-approval',
    name: 'finance payment request approval',
    component: () => import('@/views/finance/payment-request-approval'),
  },
  {
    path: '/finance/payment-request-approval/form',
    name: 'finance payment request approval form',
    component: () => import('@/views/finance/payment-request-approval/form'),
  },
  {
    path: '/finance/vendor-invoice',
    name: 'finance vendor invoice',
    component: () => import('@/views/finance/vendor-invoice'),
  },
  {
    path: '/finance/vendor-invoice/form',
    name: 'finance vendor-invoice form',
    component: () => import('@/views/finance/vendor-invoice/form'),
  },
  // {
  //   path: '/finance/vendor-credit-note',
  //   name: 'finance vendor credit note',
  //   component: () => import('@/views/finance/vendor-credit-note'),
  // },
  // {
  //   path: '/finance/vendor-credit-note/form',
  //   name: 'finance vendor credit note form',
  //   component: () => import('@/views/finance/vendor-credit-note/form'),
  // },
  // {
  //   path: '/finance/vendor-debit-note',
  //   name: 'finance vendor debit note',
  //   component: () => import('@/views/finance/vendor-debit-note'),
  // },
  // {
  //   path: '/finance/vendor-debit-note/form',
  //   name: 'finance vendor debit note form',
  //   component: () => import('@/views/finance/vendor-debit-note/form'),
  // },
  // {
  //   path: '/finance/vendor-down-payment',
  //   name: 'finance vendor down payment',
  //   component: () => import('@/views/finance/vendor-down-payment'),
  // },
  // {
  //   path: '/finance/vendor-down-payment/form',
  //   name: 'finance vendor down payment form',
  //   component: () => import('@/views/finance/vendor-down-payment/form'),
  // },
  // {
  //   path: '/finance/vendor-deposit-assignment',
  //   name: 'finance vendor-deposit-assignment',
  //   component: () => import('@/views/finance/vendor-deposit-assignment'),
  // },
  // {
  //   path: '/finance/vendor-deposit-assignment/form',
  //   name: 'finance vendor-deposit-assignment form',
  //   component: () => import('@/views/finance/vendor-deposit-assignment/form'),
  // },
  // {
  //   path: '/finance/vendor-invoice-update-information',
  //   name: 'finance update information',
  //   component: () => import('@/views/finance/vendor-invoice-update-information'),
  // },
  // {
  //   path: '/finance/vendor-invoice-update-information/form',
  //   name: 'finance vendor invoice update information form',
  //   component: () => import('@/views/finance/vendor-invoice-update-information/form'),
  // },
  // {
  //   path: '/finance/vendor-credit-note-update-information',
  //   name: 'finance vendor credit note update information',
  //   component: () => import('@/views/finance/vendor-credit-note-update-information'),
  // },
  // {
  //   path: '/finance/vendor-credit-note-update-information/form',
  //   name: 'finance vendor credit note update information form',
  //   component: () => import('@/views/finance/vendor-credit-note-update-information/form'),
  // },
  // {
  //   path: '/finance/goods-received-note-view',
  //   name: 'finance goods-received-note-view',
  //   component: () => import('@/views/finance/goods-received-note-view'),
  // },
  // //GROUP FINANCE AR
  {
    path: '/finance/bank-received',
    name: 'finance bank received',
    component: () => import('@/views/finance/bank-received'),
  },
  {
    path: '/finance/bank-received/form',
    name: 'finance bank received form',
    component: () => import('@/views/finance/bank-received/form'),
  },
  {
    path: '/finance/bank-payment',
    name: 'finance bank payment',
    component: () => import('@/views/finance/bank-payment'),
  },
  {
    path: '/finance/bank-payment/form',
    name: 'finance bank payment form',
    component: () => import('@/views/finance/bank-payment/form'),
  },
  // {
  //   path: '/finance/cash-received',
  //   name: 'finance cash received',
  //   component: () => import('@/views/finance/cash-received'),
  // },
  // {
  //   path: '/finance/cash-received/form',
  //   name: 'finance cash received form',
  //   component: () => import('@/views/finance/cash-received/form'),
  // },
  // {
  //   path: '/finance/cash-payment',
  //   name: 'finance cash payment',
  //   component: () => import('@/views/finance/cash-payment'),
  // },
  // {
  //   path: '/finance/cash-payment/form',
  //   name: 'finance cash payment form',
  //   component: () => import('@/views/finance/cash-payment/form'),
  // },
  // {
  //   path: '/finance/customer-debit-note',
  //   name: 'finance customer debit note',
  //   component: () => import('@/views/finance/customer-debit-note'),
  // },
  // {
  //   path: '/finance/customer-debit-note/form',
  //   name: 'finance customer debit note form',
  //   component: () => import('@/views/finance/customer-debit-note/form'),
  // },
  // {
  //   path: '/finance/customer-credit-note',
  //   name: 'finance customer credit note',
  //   component: () => import('@/views/finance/customer-credit-note'),
  // },
  // {
  //   path: '/finance/customer-credit-note/form',
  //   name: 'finance customer credit note form',
  //   component: () => import('@/views/finance/customer-credit-note/form'),
  // },
  // {
  //   path: '/finance/customer-down-payment',
  //   name: 'finance customer down payment',
  //   component: () => import('@/views/finance/customer-down-payment'),
  // },
  // {
  //   path: '/finance/customer-down-payment/form',
  //   name: 'finance customer down payment form',
  //   component: () => import('@/views/finance/customer-down-payment/form'),
  // },
  // {
  //   path: '/finance/giro-received',
  //   name: 'finance giro received',
  //   component: () => import('@/views/finance/giro-received'),
  // },
  // {
  //   path: '/finance/giro-received/form',
  //   name: 'finance giro received form',
  //   component: () => import('@/views/finance/giro-received/form'),
  // },
  // {
  //   path: '/finance/giro-received-rejected',
  //   name: 'finance giro received rejected',
  //   component: () => import('@/views/finance/giro-received-rejected'),
  // },
  // {
  //   path: '/finance/giro-received-rejected/form',
  //   name: 'finance giro-received-rejected form',
  //   component: () => import('@/views/finance/giro-received-rejected/form'),
  // },
  // {
  //   path: '/finance/giro-received-inquiry',
  //   name: 'finance giro received inquiry',
  //   component: () => import('@/views/finance/giro-received-inquiry'),
  // },
  // {
  //   path: '/finance/giro-payment',
  //   name: 'finance giro payment',
  //   component: () => import('@/views/finance/giro-payment'),
  // },
  // {
  //   path: '/finance/giro-payment/form',
  //   name: 'finance giro payment form',
  //   component: () => import('@/views/finance/giro-payment/form'),
  // },
  // {
  //   path: '/finance/giro-payment-rejected',
  //   name: 'finance giro payment rejected',
  //   component: () => import('@/views/finance/giro-payment-rejected'),
  // },
  // {
  //   path: '/finance/giro-payment-rejected/form',
  //   name: 'finance giro-payment-rejected form',
  //   component: () => import('@/views/finance/giro-payment-rejected/form'),
  // },
  // {
  //   path: '/finance/giro-payment-inquiry',
  //   name: 'finance giro payment inquiry',
  //   component: () => import('@/views/finance/giro-payment-inquiry'),
  // },
  // {
  //   path: '/finance/vendor-invoice',
  //   name: 'finance vendor invoice',
  //   component: () => import('@/views/finance/vendor-invoice'),
  // },
  // {
  //   path: '/finance/vendor-invoice/form',
  //   name: 'finance vendor invoice form',
  //   component: () => import('@/views/finance/vendor-invoice/form'),
  // },
  // {
  //   path: '/finance/customer-deposit-assignment',
  //   name: 'finance customer deposit assignment',
  //   component: () => import('@/views/finance/customer-deposit-assignment'),
  // },
  // {
  //   path: '/finance/customer-deposit-assignment/form',
  //   name: 'finance customer deposit assignment form',
  //   component: () => import('@/views/finance/customer-deposit-assignment/form'),
  // },
  // {
  //   path: '/finance/general-journal',
  //   name: 'finance general journal',
  //   component: () => import('@/views/finance/general-journal'),
  // },
  // {
  //   path: '/finance/general-journal/form',
  //   name: 'finance general journal form',
  //   component: () => import('@/views/finance/general-journal/form'),
  // },
  // {
  //   path: '/finance/update-customer-invoice-sales-order-information',
  //   name: 'finance update customer invoice sales order information',
  //   component: () => import('@/views/finance/update-customer-invoice-sales-order-information'),
  // },
  // {
  //   path: '/finance/update-customer-invoice-sales-order-information/form',
  //   name: 'finance update customer invoice sales order information form',
  //   component: () => import('@/views/finance/update-customer-invoice-sales-order-information/form'),
  // },
  // {
  //   path: '/finance/payment-history',
  //   name: 'payment history',
  //   component: () => import('@/views/finance/payment-history'),
  // },
  // {
  //   path: '/finance/finance-recalculating',
  //   name: 'finance recalculating',
  //   component: () => import('@/views/finance/finance-recalculating'),
  // },
  // {
  //   path: '/finance/inv-hand-over-to-finance',
  //   name: 'finance inv hand over to finance',
  //   component: () => import('@/views/finance/inv-hand-over-to-finance'),
  // },
  // {
  //   path: '/finance/inv-hand-over-to-finance/form',
  //   name: 'finance inv hand over to finance form',
  //   component: () => import('@/views/finance/inv-hand-over-to-finance/form'),
  // },
  // {
  //   path: '/finance/inv-hand-over-to-finance-approval',
  //   name: 'finance inv hand over to finance approval',
  //   component: () => import('@/views/finance/inv-hand-over-to-finance-approval'),
  // },
  // {
  //   path: '/finance/inv-hand-over-to-finance-approval/form',
  //   name: 'finance inv hand over to finance approval form',
  //   component: () => import('@/views/finance/inv-hand-over-to-finance-approval/form'),
  // },
  // {
  //   path: '/finance/invoice-hand-over-to-customer',
  //   name: 'finance invoice hand over to customer',
  //   component: () => import('@/views/finance/invoice-hand-over-to-customer'),
  // },
  // {
  //   path: '/finance/invoice-hand-over-to-customer/form',
  //   name: 'finance invoice hand over to customer form',
  //   component: () => import('@/views/finance/invoice-hand-over-to-customer/form'),
  // },
  // {
  //   path: '/finance/giro-hand-over-to-finance',
  //   name: 'finance giro hand over to finance',
  //   component: () => import('@/views/finance/giro-hand-over-to-finance'),
  // },
  // {
  //   path: '/finance/giro-hand-over-to-finance/form',
  //   name: 'finance giro hand over to finance form',
  //   component: () => import('@/views/finance/giro-hand-over-to-finance/form'),
  // },
  // {
  //   path: '/finance/giro-hand-over-to-finance-approval',
  //   name: 'finance giro hand over to finance approval',
  //   component: () => import('@/views/finance/giro-hand-over-to-finance-approval'),
  // },
  // {
  //   path: '/finance/giro-hand-over-to-finance-approval/form',
  //   name: 'finance giro hand over to finance approval form',
  //   component: () => import('@/views/finance/giro-hand-over-to-finance-approval/form'),
  // },
  // {
  //   path: '/finance/finance-closing',
  //   name: 'finance closing',
  //   component: () => import('@/views/finance/finance-closing'),
  // },
  // {
  // 	path: '/finance/dln-checklist',
  // 	name: 'finance dln checklist',
  // 	component: () => import('@/views/finance/dln-checklist'),
  // },
  // {
  // 	path: '/finance/dln-checklist/form',
  // 	name: 'finance dln checklist form',
  // 	component: () => import('@/views/finance/dln-checklist/form'),
  // },
  // {
  // 	path: '/finance/inv-hand-over-to-ar',
  // 	name: 'finance inv hand over to ar',
  // 	component: () => import('@/views/finance/inv-hand-over-to-ar'),
  // },
  // {
  // 	path: '/finance/inv-hand-over-to-ar/form',
  // 	name: 'finance inv hand over to ar form',
  // 	component: () => import('@/views/finance/inv-hand-over-to-ar/form'),
  // },
  // {
  // 	path: '/finance/inv-hand-over-to-ar-approval',
  // 	name: 'finance inv hand over to ar approval',
  // 	component: () => import('@/views/finance/inv-hand-over-to-ar-approval'),
  // },
  // {
  // 	path: '/finance/inv-hand-over-to-ar-approval/form',
  // 	name: 'finance inv hand over to ar approval form',
  // 	component: () => import('@/views/finance/inv-hand-over-to-ar-approval/form'),
  // },
  // {
  // 	path: '/finance/inv-contra-bon',
  // 	name: 'finance inv contra-bon',
  // 	component: () => import('@/views/finance/inv-contra-bon'),
  // },
  // {
  // 	path: '/finance/inv-contra-bon/form',
  // 	name: 'finance inv contra-bon form',
  // 	component: () => import('@/views/finance/inv-contra-bon/form'),
  // },
  // {
  // 	path: '/finance/giro-hand-over-to-finance',
  // 	name: 'finance giro hand over to finance',
  // 	component: () => import('@/views/finance/giro-hand-over-to-finance'),
  // },
  // {
  // 	path: '/finance/giro-hand-over-to-finance/form',
  // 	name: 'finance giro hand over to finance form',
  // 	component: () => import('@/views/finance/giro-hand-over-to-finance/form'),
  // },
];

export default finance;
