<template>
  <Toast />
  <div class="login-body">
    <div class="card login-panel p-fluid">
      <div class="login-panel-content">
        <form v-on:keyup.enter="login">
          <div class="grid">
            <div class="p-1 m-0 col-12 sm:col-6 md:col-6 logo-container">
              <h2>Sign in</h2>
            </div>
            <div class="p-1 m-0 col-12 username-container">
              <div class="p-inputgroup">
                <Dropdown v-model="form.branch" :options="listBranch" optionLabel="name" :editable="true" @change="setPeriodBranch" placeholder="Select a Branch" />
                <span class="p-inputgroup-addon">
                  <i class="pi pi-user"></i>
                </span>
              </div>
            </div>
            <div class="p-1 m-0 col-12 username-container">
              <div class="p-inputgroup">
                <InputText type="text" v-model="form.username" placeholder="Enter username" />
                <span class="p-inputgroup-addon">
                  <i class="pi pi-user"></i>
                </span>
              </div>
            </div>
            <div class="p-1 m-0 col-12 password-container">
              <div class="p-inputgroup">
                <InputText type="password" v-model="form.password" placeholder="Enter password" />
                <span class="p-inputgroup-addon">
                  <i class="pi pi-eye-slash"></i>
                </span>
              </div>
            </div>
            <div class="p-1 m-0 col-6">
              <span>Month</span>
              <div class="login-input">
                <Dropdown v-model="form.month" :options="form.dropdownMonths" :change="setPeriodMonth()" optionValue="code" optionLabel="name" :filter="true" placeholder="Select Month" />
              </div>
            </div>
            <div class="p-1 m-0 col-6">
              <span>Year</span>
              <div class="login-input">
                <Dropdown v-model="form.year" :options="form.dropdownYears" :change="setPeriodYear()" optionValue="code" optionLabel="name" :filter="true" placeholder="Select Year" />
              </div>
            </div>
            <div class="col-12 sm:col-6">
              <Button label="Sign In" :loading="isLoading" icon="pi pi-check" @click="login" />
            </div>
            <div class="col-12 sm:col-6 forgetpassword-container">
              <a href="#" class="forget-password"
                ><b>Version {{ version }}</b></a
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '@/store';
import { dataMonths, dataYears } from '@/setting/data';
import { setPeriodMonth, setPeriodYear, setPeriodBranch } from '@/utils/cookies';
import { AuthControllers } from '@/controllers/auth/AuthControllers';
export default {
  data: () => ({
    auth: new AuthControllers(),
    version: store.state.appVersion,
    isLoading: false,
    listBranch: [],
    form: {
      branch: store.state.branch,
      username: '',
      password: '',
      month: null,
      dropdownMonths: null,
      year: null,
      dropdownYears: null,
    },
  }),
  created() {
    this.form.month = String(('0' + (new Date().getMonth() + 1)).slice(-2));
    this.form.dropdownMonths = dataMonths;
    this.form.year = String(new Date().getFullYear());
    this.form.dropdownYears = dataYears;
  },
  mounted() {
    this.loadBranch();
  },
  methods: {
    authorization(branch, username, password, month, year) {
      return this.auth.authorization(branch, username, password, month, year);
    },
    setPeriodBranch() {
      setPeriodBranch(this.form.branch.code);
    },
    setPeriodMonth() {
      setPeriodMonth(this.form.month);
    },
    setPeriodYear() {
      setPeriodYear(this.form.year);
    },
    async getAccount() {
      return this.auth.account();
    },
    async login() {
      this.isLoading = true;
      await this.authorization(this.form.branch.code, this.form.username, this.form.password, this.form.month, this.form.year);
      if (store.state.isAuthenticated) {
        await this.getAccount();
        this.$router.push({ path: '/' });
      }
      this.isLoading = false;
    },
    async loadBranch() {
      this.isLoading = true;
      let data = await this.auth.loadBranch();
      this.listBranch = data;
      this.isLoading = false;
    },
  },
};
</script>
