const master = [
  //GROUP MASTER
  {
    path: '/master/customer',
    name: 'master customer',
    component: () => import('@/views/master/customer'),
  },
  {
    path: '/master/customer/form',
    name: 'master customer form',
    component: () => import('@/views/master/customer/form'),
  },
  {
    path: '/master/customer-jn-address',
    name: 'master customer jn address',
    component: () => import('@/views/master/customer-jn-address'),
  },
  {
    path: '/master/customer-jn-address/form',
    name: 'master customer jn address form',
    component: () => import('@/views/master/customer-jn-address/form'),
  },
  {
    path: '/master/bank',
    name: 'master bank',
    component: () => import('@/views/master/bank'),
  },
  {
    path: '/master/bank/form',
    name: 'master bank form',
    component: () => import('@/views/master/bank/form'),
  },
  {
    path: '/master/bank-account',
    name: 'master bank account',
    component: () => import('@/views/master/bank-account'),
  },
  {
    path: '/master/bank-account/form',
    name: 'master bank account form',
    component: () => import('@/views/master/bank-account/form'),
  },
  {
    path: '/master/courier',
    name: 'master courier',
    component: () => import('@/views/master/courier'),
  },
  {
    path: '/master/courier/form',
    name: 'master courier form',
    component: () => import('@/views/master/courier/form'),
  },
  {
    path: '/master/currency',
    name: 'master currency',
    component: () => import('@/views/master/currency'),
  },
  {
    path: '/master/currency/form',
    name: 'master currency form',
    component: () => import('@/views/master/currency/form'),
  },
  {
    path: '/master/chart-of-account',
    name: 'master chart-of-account',
    component: () => import('@/views/master/chart-of-account'),
  },
  {
    path: '/master/chart-of-account/form',
    name: 'master chart-of-account form',
    component: () => import('@/views/master/chart-of-account/form'),
  },
  {
    path: '/master/payment-term',
    name: 'master payment-term',
    component: () => import('@/views/master/payment-term'),
  },
  {
    path: '/master/payment-term/form',
    name: 'master payment-term form',
    component: () => import('@/views/master/payment-term/form'),
  },
  {
    path: '/master/vendor',
    name: 'master vendor',
    component: () => import('@/views/master/vendor'),
  },
  {
    path: '/master/vendor/form',
    name: 'master vendor form',
    component: () => import('@/views/master/vendor/form'),
  },
  {
    path: '/master/item',
    name: 'master item',
    component: () => import('@/views/master/item'),
  },
  {
    path: '/master/item/form',
    name: 'master item form',
    component: () => import('@/views/master/item/form'),
  },
  {
    path: '/master/purchase-destination',
    name: 'master purchase-destination',
    component: () => import('@/views/master/purchase-destination'),
  },
  {
    path: '/master/purchase-destination/form',
    name: 'master purchase-destination form',
    component: () => import('@/views/master/purchase-destination/form'),
  },
  {
    path: '/master/adsense',
    name: 'master adsense',
    component: () => import('@/views/master/adsense'),
  },
  {
    path: '/master/adsense/form',
    name: 'master adsense form',
    component: () => import('@/views/master/adsense/form'),
  },
  {
    path: '/master/rack',
    name: 'master rack',
    component: () => import('@/views/master/rack'),
  },
  {
    path: '/master/rack/form',
    name: 'master rack form',
    component: () => import('@/views/master/rack/form'),
  },
  {
    path: '/master/warehouse',
    name: 'master warehouse',
    component: () => import('@/views/master/warehouse'),
  },
  {
    path: '/master/warehouse/form',
    name: 'master warehouse form',
    component: () => import('@/views/master/warehouse/form'),
  },
  {
    path: '/master/approval-reason',
    name: 'master approval reason',
    component: () => import('@/views/master/approval-reason'),
  },
  {
    path: '/master/approval-reason/form',
    name: 'master approval reason form',
    component: () => import('@/views/master/approval-reason/form'),
  },
  {
    path: '/master/item-category',
    name: 'master item category',
    component: () => import('@/views/master/item-category'),
  },
  {
    path: '/master/item-category/form',
    name: 'master item category form',
    component: () => import('@/views/master/item-category/form'),
  },
  {
    path: '/master/item-division',
    name: 'master item division',
    component: () => import('@/views/master/item-division'),
  },
  {
    path: '/master/item-division/form',
    name: 'master item division form',
    component: () => import('@/views/master/item-division/form'),
  },
];

export default master;
