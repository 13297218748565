import Cookies from 'js-cookie'

export const setOauth = (token) => {
  Cookies.set(process.env.APP_IKB_NAME+'_access_token', token)
}

export const setAuthentication = (token, periodMonth, periodYear) => {
  Cookies.set(process.env.APP_IKB_NAME+'_access_token', token)
  Cookies.set(process.env.APP_IKB_NAME+'_period_month', periodMonth)
  Cookies.set(process.env.APP_IKB_NAME+'_period_year', periodYear)
}

export const setPeriodMonth = (periodMonth) => {
  Cookies.set(process.env.APP_IKB_NAME+'_period_month', periodMonth)
}

export const setPeriodYear = (periodYear) => {
  Cookies.set(process.env.APP_IKB_NAME+'_period_year', periodYear)
}

export const setPeriodBranch = (periodYear) => {
  Cookies.set(process.env.APP_IKB_NAME+'_period_branch', periodYear)
}

export const setProfile = (name, email) => {
  Cookies.set(process.env.APP_IKB_NAME+'_name', name)
  Cookies.set(process.env.APP_IKB_NAME+'_email', email)
}

export const setSetup = (setup) => {  
  Cookies.set(process.env.APP_IKB_NAME+'_salesperson', setup.username)
  Cookies.set(process.env.APP_IKB_NAME+'_fullname', setup.fullName)
  Cookies.set(process.env.APP_IKB_NAME+'_rolename', setup.roleName)
}

export const getToken = () => Cookies.get(process.env.APP_IKB_NAME+'_access_token') || null
export const getPeriodBranch = () => Cookies.get(process.env.APP_IKB_NAME+'_period_branch') || null
export const getName = () => Cookies.get(process.env.APP_IKB_NAME+'_name') || null
export const getSalesCode = () => Cookies.get(process.env.APP_IKB_NAME+'_salesperson') || null
export const getSalesName = () => Cookies.get(process.env.APP_IKB_NAME+'_fullname') || null
export const getRoleName = () => Cookies.get(process.env.APP_IKB_NAME+'_rolename') || null

export const getPeriodMonth = () => Cookies.get(process.env.APP_IKB_NAME+'_period_month') || null
export const getPeriodYear = () => Cookies.get(process.env.APP_IKB_NAME+'_period_year') || null

export const getDefaultVatPercent = () => Cookies.get(process.env.APP_IKB_NAME+'_default_vat_percent') || 11
export const getDefaultVatDivision = () => Cookies.get(process.env.APP_IKB_NAME+'_default_vat_division') || 1.11
export const getDefaultCurrencyCode = () => Cookies.get(process.env.APP_IKB_NAME+'_default_vat_currency') || 'IDR'
export const getDefaultDivisionCode = () => Cookies.get(process.env.APP_IKB_NAME+'_default_division_code') || 'DIV2'

export const removeAuth = () => {
  if(getToken()){
    Cookies.remove(process.env.APP_IKB_NAME+'_access_token')
  }
  if(getName()){
    Cookies.remove(process.env.APP_IKB_NAME+'_email')
  }
  if(getToken()){
    Cookies.remove(process.env.APP_IKB_NAME+'_name')
  }
  // if(getPeriodMonth()){
  //   Cookies.remove(process.env.APP_IKB_NAME+'_period_month')
  // }
  // if(getPeriodYear()){
  //   Cookies.remove(process.env.APP_IKB_NAME+'_period_year')
  // }
}
