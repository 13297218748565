export const searchMasterDefault = (module, data) => {
  return `/${module}/search?qry=`+JSON.stringify(data).replaceAll("{","%7B").replaceAll("}","%7D").replaceAll("[","%5B").replaceAll("]","%5D")
}
  
export const lookupMasterDefault = (module, path, data) => {
  return `/${module}/`+path+'?'+Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&')
}

export const dataMasterDefault = (module, data) => {
  return `/${module}/data?`+Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&')
}

export const saveMasterDefault = (module) => {
  return `/${module}/save`
}

export const updateMasterDefault = (module) => {
  return `/${module}/update`
}

export const deleteMasterDefault = (module,data) => {
  return `/${module}/delete?`+Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&')
}

export const authMasterPrint = (module) => {
  return `/${module}/request`
}
