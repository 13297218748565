<template>
	<div class="layout-footer">
		<div>
			<p>ERP powered by @Community</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style scoped>

</style>
