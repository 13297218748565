import axios from 'axios'
import { Login, Validates, LoadMenu, LoadBranch, CheckUser, MapDestination } from '@/api/endpoint/auth/AuthEndpoint'
import { BaseAuthUrl } from '@/api/endpoint/base'
import { store } from "@/store"

export const authRequest = (branch, username, password) => {
  const resp = axios({
    method: 'post',
    url: `${BaseAuthUrl()}${Login()}`,
    data: {
      branch: branch,
      username: username,
      password: password
    }
  }).then((response) => {
    return response
  })
  return resp
}

export const accountRequest = () => {
  const resp = axios({
    method: 'post',
    url: `${BaseAuthUrl()}${Validates()}`,
    headers: {
      'Authorization': `Bearer ${store.state.token}`,
    }
  }).then((response) => {
    return response
  })
  return resp
}

export const loadMenuRequest = () => {
  const resp = axios({
    method: 'get',
    url: `${BaseAuthUrl()}${LoadMenu()}`,
    headers: {
      'Authorization': `Bearer ${store.state.token}`,
    }
  }).then((response) => {
    return response
  })
  return resp
}

export const loadBranchRequest = () => {
  const resp = axios({
    method: 'get',
    url: `${BaseAuthUrl()}${LoadBranch()}`,
  }).then((response) => {
    return response
  })
  return resp
}

export const checkUserRequest = (username) => {
  const resp = axios({
    method: 'get',
    url: `${BaseAuthUrl()}${CheckUser(username)}`,
  }).then((response) => {
    return response
  })
  return resp
}

export const mapDestinationRequest = (data) => {
  const resp = axios({
    method: 'get',
    url: `${BaseAuthUrl()}${MapDestination(data)}`,
  }).then((response) => {
    return response
  })
  return resp
}
