import { createStore } from 'vuex';
import { removeAuth, getToken, getPeriodMonth, getPeriodYear, getPeriodBranch, getSalesCode, getSalesName, getRoleName } from '@/utils/cookies';
import EventBus from '@/setting/event-bus';
import moment from 'moment';

export const checkErrorResponse = (err) => {
  switch (err.response?.status) {
    case 500:
      if (err.response.data?.message) {
        if (err.response.data.message.toLowerCase().includes('expired') || err.response.data.message.toLowerCase() == 'invalid') {
          removeAuth();
          EventBus.emit('show-locked-panel-login', err.response.data.message);
        } else {
          EventBus.emit('show-warn-notif', err.response.data.message);
        }
      } else if (err.response?.message) {
        if (err.response.message.toLowerCase().includes('expired') || err.response.message.toLowerCase() == 'invalid') {
          removeAuth();
          EventBus.emit('show-locked-panel-login', err.response.message);
        } else {
          EventBus.emit('show-warn-notif', err.response.message);
        }
      }
      break;
    case 502:
      if (err.response.data) {
        EventBus.emit('show-warn-notif', err.response.data.message);
      } else {
        EventBus.emit('show-warn-notif', err.response.message);
      }
      break;
    case 400:
      EventBus.emit('show-warn-notif', 'Data not found!');
      break;
    case 401:
      EventBus.emit('show-locked-panel-login', 'Session Expired');
      break;
    default:
      EventBus.emit('show-warn-notif', 'Process fail!');
      break;
  }
};

export const listBranch = () => {
  return JSON.parse(localStorage.getItem('localBranchList'));  
};

export const RemoveAuth = () => {
  removeAuth();
};

const getCurrentDate = (isTime) => {
  if (isTime) {
    return moment().format('DD/MM/YYYY HH:mm');
  }
  return moment().format('DD/MM/YYYY');
};

const getPeriodDate = (isPeriod, isTime) => {
  if (isTime) {
    if (isPeriod) {
      let stDt = moment()
        .set({ year: parseInt(getPeriodYear()), month: parseInt(getPeriodMonth()) - 1 })
        .startOf('month')
        .format('DD/MM/YYYY HH:mm');
      return stDt;
    }
    let enDt = moment()
      .set({ year: parseInt(getPeriodYear()), month: parseInt(getPeriodMonth()) - 1 })
      .endOf('month')
      .format('DD/MM/YYYY HH:mm');
    return enDt;
  }
  if (isPeriod) {
    let stDt = moment()
      .set({ year: parseInt(getPeriodYear()), month: parseInt(getPeriodMonth()) - 1 })
      .startOf('month')
      .format('DD/MM/YYYY');
    return stDt;
  }
  let enDt = moment()
    .set({ year: parseInt(getPeriodYear()), month: parseInt(getPeriodMonth()) - 1 })
    .endOf('month')
    .format('DD/MM/YYYY');
  return enDt;
};

export const store = createStore({
  state: {
    appVersion: process.env.APP_VERSION,
    isAuthenticated: false,
    blockedPanel: false,
    token: getToken(),
    branch: getPeriodBranch(),
    isAuthBranch: false,
    listBranch: listBranch(),
    listDivision: [],
    listDivisionTeam: [],
    currentDate: getCurrentDate(false),
    currentDateTime: getCurrentDate(true),
    periodStartDate: getPeriodDate(true, false),
    periodEndDate: getPeriodDate(false, false),
    periodStartDateTime: getPeriodDate(true, true),
    periodEndDateTime: getPeriodDate(false, true),
    periodMonth: getPeriodMonth(),
    periodYear: getPeriodYear(),
    defaultBranchCode: getPeriodBranch(),
    defaultBranchName: null,
    defaultDivisionCode: '',
    defaultDivisionName: null,
    defaultVatPercent: 3,
    defaultVatAmount: 0,
    menus: [],
    profile: {
      username: getSalesCode(),
      fullName: getSalesName(),
      roleName: getRoleName(),
    },
  },
  getters: {
    getDate(state) {
      return state;
    },
    getToken(state) {
      return state.token;
    },
    getPeriodBranch(state) {
      return state.branch;
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      this.state.token = token;
    },
    SET_BRANCH(state, branch) {
      this.state.branch = branch;
      this.state.defaultBranchCode = branch;
    },
    SET_LISTBRANCH(state, list) {
      this.state.listBranch = list;
      this.state.listBranch.push({ code: '', name: 'All' });
    },
    SET_LISTDIVISION(state, list) {
      this.state.listDivision = list;
      this.state.listDivision.push({ code: '', name: 'All' });
    },
    SET_LISTDIVISIONTEAM(state, list) {
      this.state.listDivisionTeam = list;
    },
    SET_PERIOD_MONTH(state, periodMonth) {
      this.state.periodMonth = periodMonth;
      this.state.currentDate = getCurrentDate(false);
      this.state.currentDateTime = getCurrentDate(true);
      this.state.periodStartDate = getPeriodDate(true, false);
      this.state.periodEndDate = getPeriodDate(false, false);
      this.state.periodStartDateTime = getPeriodDate(true, true);
      this.state.periodEndDateTime = getPeriodDate(false, true);
      this.state.periodMonth = getPeriodMonth();
      this.state.periodYear = getPeriodYear();
    },
    SET_PERIOD_YEAR(state, periodYear) {
      this.state.periodYear = periodYear;
      this.state.currentDate = getCurrentDate(false);
      this.state.currentDateTime = getCurrentDate(true);
      this.state.periodStartDate = getPeriodDate(true, false);
      this.state.periodEndDate = getPeriodDate(false, false);
      this.state.periodStartDateTime = getPeriodDate(true, true);
      this.state.periodEndDateTime = getPeriodDate(false, true);
      this.state.periodMonth = getPeriodMonth();
      this.state.periodYear = getPeriodYear();
    },
    SET_SESSION(state, session) {
      this.state.session = session;
    },
    SET_AUTH(state, auth) {
      this.state.isAuthenticated = auth;
    },
    SET_BLOCK(state, auth) {
      this.state.blockedPanel = auth;
    },
    SET_SETUP(state, setup) {
      if (setup) {
        this.state.defaultBranchCode = setup?.branchCode;
        this.state.defaultBranchName = setup?.branchName;
        this.state.defaultDivisionCode = setup?.divisionCode;
        this.state.defaultDivisionName = setup?.divisionName;
        this.state.profile.username = setup?.username;
        this.state.profile.fullName = setup?.fullName;
        this.state.profile.roleName = setup?.roleName;
        this.state.profile.employee = setup?.employeeName;
      }
    },
  },
  actions: {
    storeAuth({ commit }, is) {
      commit('SET_AUTH', is);
    },
    storeBranch({ commit }, is) {
      commit('SET_BRANCH', is);
    },
    storeListBranch({ commit }, is) {
      commit('SET_LISTBRANCH', is);
    },
    storeListDivision({ commit }, is) {
      commit('SET_LISTDIVISION', is);
    },
    storeListDivisionTeam({ commit }, is) {
      commit('SET_LISTDIVISIONTEAM', is);
    },
    storeBlock({ commit }, is) {
      commit('SET_BLOCK', is);
    },
    storeToken({ commit }, token) {
      commit('SET_TOKEN', token);
    },
    storePeriodMonth({ commit }, periodMonth) {
      commit('SET_PERIOD_MONTH', periodMonth);
    },
    storePeriodYear({ commit }, periodYear) {
      commit('SET_PERIOD_YEAR', periodYear);
    },
    storeSetup({ commit }, setup) {
      commit('SET_SETUP', setup);
    },
  },
});
