const reportsPurchase = [
	//GROUP PURCHASE
	{
		path: '/reports/purchase/purchase-request',
		name: 'reports purchase-request',
		component: () => import('@/views/reports/purchase/purchase-request.vue'),
	},
	{
		path: '/reports/purchase/purchase-request-outstanding',
		name: 'reports purchase-request-outstanding',
		component: () => import('@/views/reports/purchase/purchase-request-outstanding.vue'),
	},
	{
		path: '/reports/purchase/purchase-order',
		name: 'reports purchase-order',
		component: () => import('@/views/reports/purchase/purchase-order.vue'),
	},
	{
		path: '/reports/purchase/purchase-order-per-vendor',
		name: 'reports purchase-order-per-vendor',
		component: () => import('@/views/reports/purchase/purchase-order-per-vendor.vue'),
	},
	{
		path: '/reports/purchase/purchase-order-per-item',
		name: 'reports purchase-order-per-item',
		component: () => import('@/views/reports/purchase/purchase-order-per-item.vue'),
	},
	{
		path: '/reports/purchase/purchase-order-realization',
		name: 'reports purchase-order-realization',
		component: () => import('@/views/reports/purchase/purchase-order-realization.vue'),
	},
	{
		path: '/reports/purchase/purchase-order-realization-per-vendor',
		name: 'reports purchase-order-realization-per-vendor',
		component: () => import('@/views/reports/purchase/purchase-order-realization-per-vendor.vue'),
	},
	{
		path: '/reports/purchase/purchase-order-realization-by-quantity',
		name: 'reports purchase-order-realization-by-quantity',
		component: () => import('@/views/reports/purchase/purchase-order-realization-by-quantity.vue'),
	},
	{
		path: '/reports/purchase/purchase-order-realization-by-value-and-quantity',
		name: 'reports purchase-order-realization-by-value-and-quantity',
		component: () => import('@/views/reports/purchase/purchase-order-realization-by-value-and-quantity.vue'),
	},
	{
		path: '/reports/purchase/outstanding-purchase-order-by-quantity',
		name: 'reports outstanding-purchase-order-by-quantity',
		component: () => import('@/views/reports/purchase/outstanding-purchase-order-by-quantity.vue'),
	},
	{
		path: '/reports/purchase/outstanding-purchase-order-by-value-and-quantity',
		name: 'reports outstanding-purchase-order-by-value-and-quantity',
		component: () => import('@/views/reports/purchase/outstanding-purchase-order-by-value-and-quantity.vue'),
	},
	{
		path: '/reports/purchase/purchase-recapitulation-per-vendor',
		name: 'reports purchase-recapitulation-per-vendor',
		component: () => import('@/views/reports/purchase/purchase-recapitulation-per-vendor.vue'),
	},
	{
		path: '/reports/purchase/purchase-recapitulation-per-item',
		name: 'reports purchase-recapitulation-per-item',
		component: () => import('@/views/reports/purchase/purchase-recapitulation-per-item.vue'),
	},
	{
		path: '/reports/purchase/vendor-invoice',
		name: 'reports vendor-invoice',
		component: () => import('@/views/reports/purchase/vendor-invoice.vue'),
	},
	{
		path: '/reports/purchase/vendor-invoice-per-vendor',
		name: 'reports vendor-invoice-per-vendor',
		component: () => import('@/views/reports/purchase/vendor-invoice-per-vendor.vue'),
	},
	{
		path: '/reports/purchase/vendor-invoice-per-item',
		name: 'reports vendor-invoice-per-item',
		component: () => import('@/views/reports/purchase/vendor-invoice-per-item.vue'),
	},
	{
		path: '/reports/purchase/vendor-credit-note',
		name: 'reports vendor-credit-note',
		component: () => import('@/views/reports/purchase/vendor-credit-note.vue'),
	},
	{
		path: '/reports/purchase/vendor-credit-note-per-vendor',
		name: 'reports vendor-credit-note-per-vendor',
		component: () => import('@/views/reports/purchase/vendor-credit-note-per-vendor.vue'),
	},
	{
		path: '/reports/purchase/vendor-debit-note-',
		name: 'reports vendor-debit-note-',
		component: () => import('@/views/reports/purchase/vendor-debit-note-.vue'),
	},
	{
		path: '/reports/purchase/vendor-debit-note-per-vendor',
		name: 'reports vendor-debit-note-per-vendor',
		component: () => import('@/views/reports/purchase/vendor-debit-note-per-vendor.vue'),
	},
	{
		path: '/reports/purchase/purchase-return',
		name: 'reports purchase-return',
		component: () => import('@/views/reports/purchase/purchase-return.vue'),
	},
	{
		path: '/reports/purchase/purchase-return-per-vendor',
		name: 'reports purchase-return-per-vendor',
		component: () => import('@/views/reports/purchase/purchase-return-per-vendor.vue'),
	},
	{
		path: '/reports/purchase/purchase-return-per-item',
		name: 'reports purchase-return-per-item',
		component: () => import('@/views/reports/purchase/purchase-return-per-item.vue'),
	},
	{
		path: '/reports/purchase/unassign-purchase-request-by-purchase-order',
		name: 'reports unassign-purchase-request-by-purchase-order',
		component: () => import('@/views/reports/purchase/unassign-purchase-request-by-purchase-order.vue'),
	},
	{
		path: '/reports/purchase/unassign-purchase-order-by-goods-received-note',
		name: 'reports unassign-purchase-order-by-goods-received-note',
		component: () => import('@/views/reports/purchase/unassign-purchase-order-by-goods-received-note.vue'),
	},
	{
		path: '/reports/purchase/unassign-vendor-invoice-by-payment-request',
		name: 'reports unassign-vendor-invoice-by-payment-request',
		component: () => import('@/views/reports/purchase/unassign-vendor-invoice-by-payment-request.vue'),
	},
	{
		path: '/reports/purchase/purchase-order-global-without-price',
		name: 'reports purchase-order-global-without-price',
		component: () => import('@/views/reports/purchase/purchase-order-global-without-price.vue'),
	},
	{
		path: '/reports/purchase/purchase-order-per-vendor-without-price',
		name: 'reports purchase-order-per-vendor-without-price',
		component: () => import('@/views/reports/purchase/purchase-order-per-vendor-without-price.vue'),
	},
	{
		path: '/reports/purchase/purchase-order-per-item-without-price',
		name: 'reports purchase-order-per-item-without-price',
		component: () => import('@/views/reports/purchase/purchase-order-per-item-without-price.vue'),
	},
];

export default reportsPurchase;
