const purchase = [
	//GROUP SALES
	{
		path: '/purchase/purchase-request',
		name: 'purchase request',
		component: () => import('@/views/purchase/purchase-request'),
	},
	{
		path: '/purchase/purchase-request/form',
		name: 'purchase request form',
		component: () => import('@/views/purchase/purchase-request/form'),
	},
	{
		path: '/purchase/purchase-request-closing',
		name: 'purchase request closing',
		component: () => import('@/views/purchase/purchase-request-closing'),
	},
	{
		path: '/purchase/purchase-request-closing/form',
		name: 'purchase request closing form',
		component: () => import('@/views/purchase/purchase-request-closing/form'),
	},
	{
		path: '/purchase/purchase-order',
		name: 'purchase order',
		component: () => import('@/views/purchase/purchase-order'),
	},
	{
		path: '/purchase/purchase-order/form',
		name: 'purchase order form',
		component: () => import('@/views/purchase/purchase-order/form'),
	},
	{
		path: '/purchase/purchase-order-closing',
		name: 'purchase order closing',
		component: () => import('@/views/purchase/purchase-order-closing'),
	},
	{
		path: '/purchase/purchase-order-closing/form',
		name: 'purchase order closing form',
		component: () => import('@/views/purchase/purchase-order-closing/form'),
	},
	// {
	// 	path: '/purchase/purchase-return',
	// 	name: 'purchase return',
	// 	component: () => import('@/views/purchase/purchase-return'),
	// },
	// {
	// 	path: '/purchase/purchase-return/form',
	// 	name: 'purchase return form',
	// 	component: () => import('@/views/purchase/purchase-return/form'),
	// },
	// {
	// 	path: '/purchase/purchase-history',
	// 	name: 'purchase history',
	// 	component: () => import('@/views/purchase/purchase-history'),
	// },
];

export default purchase;
