const reportsFinance = [
  //GROUP PURCHASE
  {
    path: '/reports/finance/ar-aging',
    name: 'reports ar-aging',
    component: () => import('@/views/reports/finance/ar-aging.vue'),
  },
  {
    path: '/reports/finance/ar-aging-per-customer',
    name: 'reports ar-aging-per-customer',
    component: () => import('@/views/reports/finance/ar-aging-per-customer.vue'),
  },
  {
    path: '/reports/finance/ar-aging-per-sales-person',
    name: 'reports ar-aging-per-sales-person',
    component: () => import('@/views/reports/finance/ar-aging-per-sales-person.vue'),
  },
  {
    path: '/reports/finance/ar-balance',
    name: 'reports ar-balance',
    component: () => import('@/views/reports/finance/ar-balance.vue'),
  },
  {
    path: '/reports/finance/ar-balance-per-customer',
    name: 'reports ar-balance-per-customer',
    component: () => import('@/views/reports/finance/ar-balance-per-customer.vue'),
  },
  {
    path: '/reports/finance/ar-balance-per-sales-person',
    name: 'reports ar-balance-per-sales-person',
    component: () => import('@/views/reports/finance/ar-balance-per-sales-person.vue'),
  },
  {
    path: '/reports/finance/ar-balance-per-transaction-date',
    name: 'reports ar-balance-per-transaction-date',
    component: () => import('@/views/reports/finance/ar-balance-per-transaction-date.vue'),
  },
  {
    path: '/reports/finance/ar-received',
    name: 'reports ar-received',
    component: () => import('@/views/reports/finance/ar-received.vue'),
  },
  {
    path: '/reports/finance/ar-received-per-customer',
    name: 'reports ar-received-per-customer',
    component: () => import('@/views/reports/finance/ar-received-per-customer.vue'),
  },
  {
    path: '/reports/finance/ar-received-per-sales-person',
    name: 'reports ar-received-per-sales-person',
    component: () => import('@/views/reports/finance/ar-received-per-sales-person.vue'),
  },
  {
    path: '/reports/finance/ap-aging',
    name: 'reports ap-aging',
    component: () => import('@/views/reports/finance/ap-aging.vue'),
  },
  {
    path: '/reports/finance/ap-aging-per-vendor',
    name: 'reports ap-aging-per-vendor',
    component: () => import('@/views/reports/finance/ap-aging-per-vendor.vue'),
  },
  {
    path: '/reports/finance/ap-balance',
    name: 'reports ap-balance',
    component: () => import('@/views/reports/finance/ap-balance.vue'),
  },
  {
    path: '/reports/finance/ap-balance-per-vendor',
    name: 'reports ap-balance-per-vendor',
    component: () => import('@/views/reports/finance/ap-balance-per-vendor.vue'),
  },
  {
    path: '/reports/finance/ap-payment',
    name: 'reports ap-payment',
    component: () => import('@/views/reports/finance/ap-payment.vue'),
  },
  {
    path: '/reports/finance/ap-payment-per-vendor',
    name: 'reports ap-payment-per-vendor',
    component: () => import('@/views/reports/finance/ap-payment-per-vendor.vue'),
  },
  {
    path: '/reports/finance/cash-receiving',
    name: 'reports cash-receiving',
    component: () => import('@/views/reports/finance/cash-receiving.vue'),
  },
  {
    path: '/reports/finance/cash-receiving-per-cash-account',
    name: 'reports cash-receiving-per-cash-account',
    component: () => import('@/views/reports/finance/cash-receiving-per-cash-account.vue'),
  },
  {
    path: '/reports/finance/cash-receiving-per-chart-of-account',
    name: 'reports cash-receiving-per-chart-of-account',
    component: () => import('@/views/reports/finance/cash-receiving-per-chart-of-account.vue'),
  },
  {
    path: '/reports/finance/bank-receiving',
    name: 'reports bank-receiving',
    component: () => import('@/views/reports/finance/bank-receiving.vue'),
  },
  {
    path: '/reports/finance/bank-receiving-per-bank-account',
    name: 'reports bank-receiving-per-bank-account',
    component: () => import('@/views/reports/finance/bank-receiving-per-bank-account.vue'),
  },
  {
    path: '/reports/finance/bank-receiving-per-chart-of-account',
    name: 'reports bank-receiving-per-chart-of-account',
    component: () => import('@/views/reports/finance/bank-receiving-per-chart-of-account.vue'),
  },
  {
    path: '/reports/finance/cash-payment',
    name: 'reports cash-payment',
    component: () => import('@/views/reports/finance/cash-payment.vue'),
  },
  {
    path: '/reports/finance/cash-payment-per-cash-account',
    name: 'reports cash-payment-per-cash-account',
    component: () => import('@/views/reports/finance/cash-payment-per-cash-account.vue'),
  },
  {
    path: '/reports/finance/cash-payment-per-chart-of-account',
    name: 'reports cash-payment-per-chart-of-account',
    component: () => import('@/views/reports/finance/cash-payment-per-chart-of-account.vue'),
  },
  {
    path: '/reports/finance/bank-payment',
    name: 'reports bank-payment',
    component: () => import('@/views/reports/finance/bank-payment.vue'),
  },
  {
    path: '/reports/finance/bank-payment-per-bank-account',
    name: 'reports bank-payment-per-bank-account',
    component: () => import('@/views/reports/finance/bank-payment-per-bank-account.vue'),
  },
  {
    path: '/reports/finance/bank-payment-per-chart-of-account',
    name: 'reports bank-payment-per-chart-of-account',
    component: () => import('@/views/reports/finance/bank-payment-per-chart-of-account.vue'),
  },
  {
    path: '/reports/finance/bank-mutation',
    name: 'reports bank-mutation',
    component: () => import('@/views/reports/finance/bank-mutation.vue'),
  },
  {
    path: '/reports/finance/cash-mutation',
    name: 'reports cash-mutation',
    component: () => import('@/views/reports/finance/cash-mutation.vue'),
  },
  {
    path: '/reports/finance/unassign-goods-received-note-by-vendor-invoice',
    name: 'reports unassign-goods-received-note-by-vendor-invoice',
    component: () => import('@/views/reports/finance/unassign-goods-received-note-by-vendor-invoice.vue'),
  },
  {
    path: '/reports/finance/unassign-delivery-note-customer-invoice',
    name: 'reports unassign-delivery-note-customer-invoice',
    component: () => import('@/views/reports/finance/unassign-delivery-note-customer-invoice.vue'),
  },
  {
    path: '/reports/finance/outstanding-bank-received-deposit',
    name: 'reports outstanding-bank-received-deposit',
    component: () => import('@/views/reports/finance/outstanding-bank-received-deposit.vue'),
  },
  {
    path: '/reports/finance/outstanding-bank-payment-deposit',
    name: 'reports outstanding-bank-payment-deposit',
    component: () => import('@/views/reports/finance/outstanding-bank-payment-deposit.vue'),
  },
  {
    path: '/reports/finance/outstanding-cash-received-deposit',
    name: 'reports outstanding-cash-received-deposit',
    component: () => import('@/views/reports/finance/outstanding-cash-received-deposit.vue'),
  },
  {
    path: '/reports/finance/outstanding-cash-payment-deposit',
    name: 'reports outstanding-cash-payment-deposit',
    component: () => import('@/views/reports/finance/outstanding-cash-payment-deposit.vue'),
  },
  {
    path: '/reports/finance/unassigned-bank-received-deposit',
    name: 'reports unassigned-bank-received-deposit',
    component: () => import('@/views/reports/finance/unassigned-bank-received-deposit.vue'),
  },
  {
    path: '/reports/finance/unassigned-bank-payment-deposit',
    name: 'reports unassigned-bank-payment-deposit',
    component: () => import('@/views/reports/finance/unassigned-bank-payment-deposit.vue'),
  },
  {
    path: '/reports/finance/unassigned-cash-received-deposit',
    name: 'reports unassigned-cash-received-deposit',
    component: () => import('@/views/reports/finance/unassigned-cash-received-deposit.vue'),
  },
  {
    path: '/reports/finance/unassigned-cash-payment-deposit',
    name: 'reports unassigned-cash-payment-deposit',
    component: () => import('@/views/reports/finance/unassigned-cash-payment-deposit.vue'),
  },
  {
    path: '/reports/finance/unassign-vendor-invoice-payment-request',
    name: 'reports unassign-vendor-invoice-payment-request',
    component: () => import('@/views/reports/finance/unassign-vendor-invoice-payment-request.vue'),
  },
  {
    path: '/reports/finance/unassign-payment-request-payment',
    name: 'reports unassign-payment-request-payment',
    component: () => import('@/views/reports/finance/unassign-payment-request-payment.vue'),
  },
  {
    path: '/reports/finance/cash-payment-recapitulation-per-periode',
    name: 'reports cash-payment-recapitulation-per-periode',
    component: () => import('@/views/reports/finance/cash-payment-recapitulation-per-periode.vue'),
  },
  {
    path: '/reports/finance/cash-received-recapitulation-per-periode',
    name: 'reports cash-received-recapitulation-per-periode',
    component: () => import('@/views/reports/finance/cash-received-recapitulation-per-periode.vue'),
  },
  {
    path: '/reports/finance/bank-payment-recapitulation-per-periode',
    name: 'reports bank-payment-recapitulation-per-periode',
    component: () => import('@/views/reports/finance/bank-payment-recapitulation-per-periode.vue'),
  },
  {
    path: '/reports/finance/bank-received-recapitulation-per-periode',
    name: 'reports bank-received-recapitulation-per-periode',
    component: () => import('@/views/reports/finance/bank-received-recapitulation-per-periode.vue'),
  },
  {
    path: '/reports/finance/customer-down-payment-per-period',
    name: 'reports customer-down-payment-per-period',
    component: () => import('@/views/reports/finance/customer-down-payment-per-period.vue'),
  },
  {
    path: '/reports/finance/customer-down-payment-outstanding',
    name: 'reports customer-down-payment-outstanding',
    component: () => import('@/views/reports/finance/customer-down-payment-outstanding.vue'),
  },
  {
    path: '/reports/finance/vendor-down-payment-per-period',
    name: 'reports vendor-down-payment-per-period',
    component: () => import('@/views/reports/finance/vendor-down-payment-per-period.vue'),
  },
  {
    path: '/reports/finance/vendor-down-payment-outstanding',
    name: 'reports vendor-down-payment-outstanding',
    component: () => import('@/views/reports/finance/vendor-down-payment-outstanding.vue'),
  },
  {
    path: '/reports/finance/customer-deposit',
    name: 'reports customer-deposit',
    component: () => import('@/views/reports/finance/customer-deposit.vue'),
  },
  {
    path: '/reports/finance/customer-deposit-outstanding',
    name: 'reports customer-deposit-outstanding',
    component: () => import('@/views/reports/finance/customer-deposit-outstanding.vue'),
  },
  {
    path: '/reports/finance/vendor-deposit',
    name: 'reports vendor-deposit',
    component: () => import('@/views/reports/finance/vendor-deposit.vue'),
  },
  {
    path: '/reports/finance/vendor-deposit-outstanding',
    name: 'reports vendor-deposit-outstanding',
    component: () => import('@/views/reports/finance/vendor-deposit-outstanding.vue'),
  },
  {
    path: '/reports/finance/received-aging',
    name: 'reports received-aging',
    component: () => import('@/views/reports/finance/received-aging.vue'),
  },
  {
    path: '/reports/finance/outstanding-sales-person',
    name: 'reports outstanding-sales-person',
    component: () => import('@/views/reports/finance/outstanding-sales-person.vue'),
  },
  {
    path: '/reports/finance/vat-out-pajak',
    name: 'reports vat out pajak',
    component: () => import('@/views/reports/finance/vat-out-pajak.vue'),
  },
];

export default reportsFinance;
