const warehouse = [
	//GROUP INVENTORY
	{
		path: '/warehouse/goods-received-note',
		name: 'goods received note',
		component: () => import('@/views/warehouse/goods-received-note'),
	},
	{
		path: '/warehouse/goods-received-note/form',
		name: 'goods received note form',
		component: () => import('@/views/warehouse/goods-received-note/form'),
	},
	{
		path: '/warehouse/adjustment-in',
		name: 'adjustment in',
		component: () => import('@/views/warehouse/adjustment-in'),
	},
	{
		path: '/warehouse/adjustment-in/form',
		name: 'adjustment in form',
		component: () => import('@/views/warehouse/adjustment-in/form'),
	},
	{
		path: '/warehouse/adjustment-out',
		name: 'adjustment out',
		component: () => import('@/views/warehouse/adjustment-out'),
	},
	{
		path: '/warehouse/adjustment-out/form',
		name: 'adjustment out form',
		component: () => import('@/views/warehouse/adjustment-out/form'),
	},
	{
		path: '/warehouse/warehouse-transfer-in',
		name: 'warehouse transfer in',
		component: () => import('@/views/warehouse/warehouse-transfer-in'),
	},
	{
		path: '/warehouse/warehouse-transfer-in/form',
		name: 'warehouse transfer in form',
		component: () => import('@/views/warehouse/warehouse-transfer-in/form'),
	},
	{
		path: '/warehouse/warehouse-transfer-out',
		name: 'warehouse transfer out',
		component: () => import('@/views/warehouse/warehouse-transfer-out'),
	},
	{
		path: '/warehouse/warehouse-transfer-out/form',
		name: 'warehouse transfer out form',
		component: () => import('@/views/warehouse/warehouse-transfer-out/form'),
	},
	{
		path: '/warehouse/picking-list',
		name: 'picking list',
		component: () => import('@/views/warehouse/picking-list'),
	},
	{
		path: '/warehouse/picking-list/form',
		name: 'picking list form',
		component: () => import('@/views/warehouse/picking-list/form'),
	},
	{
		path: '/warehouse/delivery-note',
		name: 'delivery note',
		component: () => import('@/views/warehouse/delivery-note'),
	},
	{
		path: '/warehouse/delivery-note/form',
		name: 'delivery note form',
		component: () => import('@/views/warehouse/delivery-note/form'),
	},
	{
		path: '/warehouse/delivery-note-return',
		name: 'delivery note return',
		component: () => import('@/views/warehouse/delivery-note-return'),
	},
	{
		path: '/warehouse/delivery-note-return/form',
		name: 'delivery note return form',
		component: () => import('@/views/warehouse/delivery-note-return/form'),
	},
	// {
	// 	path: '/warehouse/un-assign-picking-list-delivery-note',
	// 	name: 'un assign picking list delivery note',
	// 	component: () => import('@/views/warehouse/un-assign-picking-list-delivery-note'),
	// },
	// {
	// 	path: '/warehouse/un-assign-picking-list-delivery-note/form',
	// 	name: 'un assign picking list delivery note form',
	// 	component: () => import('@/views/warehouse/un-assign-picking-list-delivery-note/form'),
	// },
	{
		path: '/warehouse/current-stock-quantity',
		name: 'current stock quantity',
		component: () => import('@/views/warehouse/current-stock-quantity'),
	},
	// {
	// 	path: '/warehouse/dock-stock',
	// 	name: 'dock stock',
	// 	component: () => import('@/views/warehouse/dock-stock'),
	// },
	// {
	// 	path: '/warehouse/assembly-job-order',
	// 	name: 'assembly job order',
	// 	component: () => import('@/views/warehouse/assembly-job-order'),
	// },
	// {
	// 	path: '/warehouse/assembly-job-order/form',
	// 	name: 'assembly job order form',
	// 	component: () => import('@/views/warehouse/assembly-job-order/form'),
	// },
	// {
	// 	path: '/warehouse/assembly-realization',
	// 	name: 'assembly realization',
	// 	component: () => import('@/views/warehouse/assembly-realization'),
	// },
	// {
	// 	path: '/warehouse/assembly-realization/form',
	// 	name: 'assembly realization form',
	// 	component: () => import('@/views/warehouse/assembly-realization/form'),
	// },
	// {
	// 	path: '/warehouse/stock-closing',
	// 	name: 'stock closing',
	// 	component: () => import('@/views/warehouse/stock-closing'),
	// },
];

export default warehouse;
