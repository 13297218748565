export const searchSettingDefault = (module, data) => {
    return `/${module}/search?qry=`+JSON.stringify(data).replaceAll("{","%7B").replaceAll("}","%7D")
  }

  export const lookupSettingDefault = (module, path, data) => {
    return `/${module}/`+path+'?'+Object.keys(data).map(key => key + '=' + data[key]).join('&')
  }

  export const dataSettingDefault = (module, data) => {
    return `/${module}/data?`+Object.keys(data).map(key => key + '=' + data[key]).join('&')
  }

  export const saveSettingDefault = (module) => {
    return `/${module}/save`
  }

  export const updateSettingDefault = (module) => {
    return `/${module}/update`
  }

  export const deleteSettingDefault = (module,data) => {
    return `/${module}/delete?`+Object.keys(data).map(key => key + '=' + data[key]).join('&')
  }
