const reportsWarehouse = [
  //GROUP PURCHASE

  {
    path: '/reports/warehouse/detail-stock-by-inventory',
    name: 'reports detail-stock-by-inventory',
    component: () => import('@/views/reports/warehouse/detail-stock-by-inventory.vue'),
  },
  {
    path: '/reports/warehouse/detail-stock-by-quantity',
    name: 'reports detail-stock-by-quantity',
    component: () => import('@/views/reports/warehouse/detail-stock-by-quantity.vue'),
  },
  {
    path: '/reports/warehouse/goods-received-note',
    name: 'reports goods-received-note',
    component: () => import('@/views/reports/warehouse/goods-received-note.vue'),
  },
  {
    path: '/reports/warehouse/goods-received-note-per-vendor',
    name: 'reports goods-received-note-per-vendor',
    component: () => import('@/views/reports/warehouse/goods-received-note-per-vendor.vue'),
  },
  {
    path: '/reports/warehouse/goods-received-note-per-item',
    name: 'reports goods-received-note-per-item',
    component: () => import('@/views/reports/warehouse/goods-received-note-per-item.vue'),
  },
  {
    path: '/reports/warehouse/delivery-note',
    name: 'reports delivery-note',
    component: () => import('@/views/reports/warehouse/delivery-note.vue'),
  },
  {
    path: '/reports/warehouse/delivery-note-per-customer',
    name: 'reports delivery-note-per-customer',
    component: () => import('@/views/reports/warehouse/delivery-note-per-customer.vue'),
  },
  {
    path: '/reports/warehouse/delivery-note-by-item',
    name: 'reports delivery-note-by-item',
    component: () => import('@/views/reports/warehouse/delivery-note-by-item.vue'),
  },
  {
    path: '/reports/warehouse/inventory-in',
    name: 'reports inventory-in',
    component: () => import('@/views/reports/warehouse/inventory-in.vue'),
  },
  {
    path: '/reports/warehouse/inventory-in-per-reason',
    name: 'reports inventory-in-per-reason',
    component: () => import('@/views/reports/warehouse/inventory-in-per-reason.vue'),
  },
  {
    path: '/reports/warehouse/inventory-in-per-item',
    name: 'reports inventory-in-per-item',
    component: () => import('@/views/reports/warehouse/inventory-in-per-item.vue'),
  },
  {
    path: '/reports/warehouse/inventory-out',
    name: 'reports inventory-out',
    component: () => import('@/views/reports/warehouse/inventory-out.vue'),
  },
  {
    path: '/reports/warehouse/inventory-out-per-reason',
    name: 'reports inventory-out-per-reason',
    component: () => import('@/views/reports/warehouse/inventory-out-per-reason.vue'),
  },
  {
    path: '/reports/warehouse/inventory-out-per-item',
    name: 'reports inventory-out-per-item',
    component: () => import('@/views/reports/warehouse/inventory-out-per-item.vue'),
  },
  {
    path: '/reports/warehouse/assembly-job-order',
    name: 'reports assembly-job-order',
    component: () => import('@/views/reports/warehouse/assembly-job-order.vue'),
  },
  {
    path: '/reports/warehouse/assembly-realization',
    name: 'reports assembly-realization',
    component: () => import('@/views/reports/warehouse/assembly-realization.vue'),
  },
  {
    path: '/reports/warehouse/assembly-realization-per-item',
    name: 'reports assembly-realization-per-item',
    component: () => import('@/views/reports/warehouse/assembly-realization-per-item.vue'),
  },
  {
    path: '/reports/warehouse/schedule-of-inventory',
    name: 'reports schedule-of-inventory',
    component: () => import('@/views/reports/warehouse/schedule-of-inventory.vue'),
  },
  {
    path: '/reports/warehouse/stock-history-global',
    name: 'reports stock-history-global',
    component: () => import('@/views/reports/warehouse/stock-history-global.vue'),
  },
  {
    path: '/reports/warehouse/stock-history-detail',
    name: 'reports stock-history-detail',
    component: () => import('@/views/reports/warehouse/stock-history-detail.vue'),
  },
  {
    path: '/reports/warehouse/stock-card-minus-quantity',
    name: 'reports stock-card-minus-quantity',
    component: () => import('@/views/reports/warehouse/stock-card-minus-quantity.vue'),
  },
  {
    path: '/reports/warehouse/stock-card-quantity-per-warehouse',
    name: 'reports stock-card-quantity-per-warehouse',
    component: () => import('@/views/reports/warehouse/stock-card-quantity-per-warehouse.vue'),
  },
  {
    path: '/reports/warehouse/stock-card-cogs-idr-global',
    name: 'reports stock-card-cogs-idr-global',
    component: () => import('@/views/reports/warehouse/stock-card-cogs-idr-global.vue'),
  },
  {
    path: '/reports/warehouse/warehouse-transfer-out',
    name: 'reports warehouse-transfer-out',
    component: () => import('@/views/reports/warehouse/warehouse-transfer-out.vue'),
  },
  {
    path: '/reports/warehouse/warehouse-transfer-in',
    name: 'reports warehouse-transfer-in',
    component: () => import('@/views/reports/warehouse/warehouse-transfer-in.vue'),
  },
  {
    path: '/reports/warehouse/adjustment-report',
    name: 'reports adjustment report',
    component: () => import('@/views/reports/warehouse/adjustment-report.vue'),
  },
  {
    path: '/reports/warehouse/adjustment-inventory-in',
    name: 'reports adjustment inventory in',
    component: () => import('@/views/reports/warehouse/adjustment-inventory-in.vue'),
  },
  {
    path: '/reports/warehouse/adjustment-inventory-out',
    name: 'reports adjustment inventory out',
    component: () => import('@/views/reports/warehouse/adjustment-inventory-out.vue'),
  },
];

export default reportsWarehouse;
