export const dataMonths = [
  {name: 'Januari', code: '01'},
  {name: 'Februari', code: '02'},
  {name: 'Maret', code: '03'},
  {name: 'April', code: '04'},
  {name: 'Mei', code: '05'},
  {name: 'Juni', code: '06'},
  {name: 'Juli', code: '07'},
  {name: 'Agustus', code: '08'},
  {name: 'September', code: '09'},
  {name: 'Oktober', code: '10'},
  {name: 'November', code: '11'},
  {name: 'Desember', code: '12'},
];

export const dataYears = [
  {name: '2000', code: '2000'},
  {name: '2001', code: '2001'},
  {name: '2002', code: '2002'},
  {name: '2003', code: '2003'},
  {name: '2004', code: '2004'},
  {name: '2005', code: '2005'},
  {name: '2006', code: '2006'},
  {name: '2007', code: '2007'},
  {name: '2008', code: '2008'},
  {name: '2009', code: '2009'},
  {name: '2010', code: '2010'},
  {name: '2011', code: '2011'},
  {name: '2012', code: '2012'},
  {name: '2013', code: '2013'},
  {name: '2014', code: '2014'},
  {name: '2015', code: '2015'},
  {name: '2016', code: '2016'},
  {name: '2017', code: '2017'},
  {name: '2018', code: '2018'},
  {name: '2019', code: '2019'},
  {name: '2020', code: '2020'},
  {name: '2021', code: '2021'},
  {name: '2022', code: '2022'},
  {name: '2023', code: '2023'},
  {name: '2024', code: '2024'},
  {name: '2025', code: '2025'},
  {name: '2026', code: '2026'},
  {name: '2027', code: '2027'},
  {name: '2028', code: '2028'},
  {name: '2029', code: '2029'},
  {name: '2030', code: '2030'},
];
