import {createRouter, createWebHashHistory} from 'vue-router';
import EventBus from '@/setting/event-bus';
import { store } from "@/store";
import master from "@/router/master.js";
import purchase from "@/router/purchase.js";
import sales from "@/router/sales.js";
import warehouse from "@/router/warehouse.js";
import finance from "@/router/finance.js";
import accounting from "@/router/accounting.js";
import reportsPurchase from "@/router/reportsPurchase.js";
import reportsSales from "@/router/reportsSales.js";
import reportsWarehouse from "@/router/reportsWarehouse.js";
import reportsFinance from "@/router/reportsFinance.js";
import reportsAccounting from "@/router/reportsAccounting.js";

const routes = [
	{
		path: '/',
		name: 'Dashboard',
		exact: true,
		meta: {requiresAuth: false},
		component: () => import('@/views/home/Dashboard')
	},
	{
		path: '/setting',
		name: 'Setting',
		meta: {requiresAuth: true},
		component: () => import('@/views/profile/Setting')
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/auth/Login')
	},
	{
		path: '/access',
		name: 'Access',
		component: () => import('@/views/auth/Access')
	},
	{
		path: '/error',
		name: 'Error',
		component: () => import('@/views/auth/Error')
	},
	{
		path: '/help',
		name: 'Help',
		component: () => import('@/views/auth/Help')
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import('@/views/auth/NotFound')
	},
	{
		path: '/setting/user',
		name: 'user',
		component: () => import('@/views/setting/user')
	},
	{
		path: '/setting/user/form',
		name: 'user-form',
		component: () => import('@/views/setting/user/form')
	},
	{
		path: '/profile',
		name: 'profile',
		component: () => import('@/views/setting/user/profile')
	},
	{
		path: '/setting/role',
		name: 'role',
		component: () => import('@/views/setting/role')
	},
	{
		path: '/setting/role/form',
		name: 'role-form',
		component: () => import('@/views/setting/role/form')
	},
	{
		path: '/setting/role-authorization',
		name: 'role-authorization',
		component: () => import('@/views/setting/role-authorization')
	},
	{
		path: '/setting/role-authorization/form',
		name: 'role-authorization-form',
		component: () => import('@/views/setting/role-authorization/form')
	},
	{
		path: '/setting/branch',
		name: 'branch',
		component: () => import('@/views/setting/branch')
	},
	{
		path: '/setting/branch/form',
		name: 'branch-form',
		component: () => import('@/views/setting/branch/form')
	},
	{
		path: '/setting/division',
		name: 'division',
		component: () => import('@/views/setting/division')
	},
	{
		path: '/setting/division/form',
		name: 'division-form',
		component: () => import('@/views/setting/division/form')
	},
	{
		path: '/setting/division-team',
		name: 'division-team',
		component: () => import('@/views/setting/division-team')
	},
	{
		path: '/setting/division-team/form',
		name: 'division-team-form',
		component: () => import('@/views/setting/division-team/form')
	},
	{
		path: '/setting/sales-commission',
		name: 'sales-commission',
		component: () => import('@/views/setting/sales-commission')
	},
	{
		path: '/setting/barcode',
		name: 'barcode',
		component: () => import('@/views/setting/barcode')
	},
	{
		path: '/setting/barcode/form',
		name: 'barcode-form',
		component: () => import('@/views/setting/barcode/form')
	},
].concat(master).concat(purchase).concat(sales).concat(warehouse).concat(finance).concat(accounting).concat(reportsPurchase).concat(reportsSales).concat(reportsWarehouse).concat(reportsFinance).concat(reportsAccounting);

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior () {
		return { left: 0, top: 0 };
	}
});

router.beforeEach((to, from, next) => {
	// if (to.name !== "Login" && to.name !== "Dashboard" && store.state.currentDate.includes("null")) {
	// 	EventBus.emit("reload-app");
	// }

	if (to.name !== "Login" && store.profile?.name) {
		router.push({ path: '/login' }).then(() => { router.go() });
		return;
	}

	if(store.state.blockedPanelLogin){
		EventBus.emit("show-locked-panel-login","Session Expired");
		return
	}
	if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.isAuthenticated) {
      next("/access");
    }
  }

  next();
});
export default router;
