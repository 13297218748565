import {
    searchSettingDefaultRequest,
    lookupSettingDefaultRequest,
    dataSettingDefaultRequest,
    saveSettingDefaultRequest,
    updateSettingDefaultRequest,
    deleteSettingDefaultRequest
  } from '@/api/request/setting/SettingRequest'
  import { checkErrorResponse } from '@/store';

  export class SettingControllers {


    searchSetting(module, data) {
      let resp = null;
      resp = searchSettingDefaultRequest(module, data)
        .then((response) => {
          return response.data;
        }).catch((err) => {
          checkErrorResponse(err)
        }).finally(() => {
        })
      return resp;
    }

    lookupSetting(module, data) {
      let resp = null;
      resp = lookupSettingDefaultRequest(module, data)
      .then((response) => {
        return response.data;
      }).catch((err) => {
        checkErrorResponse(err)
      }).finally(() => {
      })
      return resp;
    }

    dataSetting(module, data) {
      let resp = null;
      resp = dataSettingDefaultRequest(module, data)
      .then((response) => {
        return response.data;
      }).catch((err) => {
        checkErrorResponse(err)
      }).finally(() => {
      })
      return resp;
    }

    saveSetting(module, data) {
      let resp = null;
      resp = saveSettingDefaultRequest(module, data)
      .then((response) => {
        return response;
      }).catch((err) => {
        checkErrorResponse(err)
      }).finally(() => {
      })
      return resp;
    }

    updateSetting(module, data) {
      let resp = null;
      resp = updateSettingDefaultRequest(module, data)
      .then((response) => {
        return response;
      }).catch((err) => {
        checkErrorResponse(err)
      }).finally(() => {
      })
      return resp;
    }

    deleteSetting(module, data) {
      let resp = null;
      resp = deleteSettingDefaultRequest(module, data)
      .then((response) => {
        return response;
      }).catch((err) => {
        checkErrorResponse(err)
      }).finally(() => {
      })
      return resp;
    }
  }
