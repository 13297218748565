<template>
  <Dialog styleClass="surface-ground" :header="titleLoginPanel + ', Please Login'" v-model:visible="blockLoginPanel"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '40vw' }">
    <div style="display: flex; justify-content: center">
      <div class="card login-panel p-fluid col-12 md:col-12 p-0 m-0"
        style="background: darkgray; display: flex; justify-content: center">
        <div class="login-panel-content p-3 m-3">
          <form v-on:keyup.enter="login">
            <div class="grid">
              <div class="p-1 m-0 col-12 username-container">
                <div class="p-inputgroup">
                  <InputText type="text" v-model="form.branch" placeholder="Enter branch" disabled="true" />
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-user"></i>
                  </span>
                </div>
              </div>
              <div class="p-1 m-0 col-12 username-container">
                <div class="p-inputgroup">
                  <InputText type="text" v-model="form.username" placeholder="Enter username" />
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-user"></i>
                  </span>
                </div>
              </div>
              <div class="p-1 m-0 col-12 password-container">
                <div class="p-inputgroup">
                  <InputText type="password" v-model="form.password" placeholder="Enter password" />
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-eye-slash"></i>
                  </span>
                </div>
              </div>
              <div class="p-1 m-0 col-6">
                <span>Month</span>
                <div class="login-input">
                  <Dropdown v-model="form.month" :options="form.dropdownMonths" optionValue="code" optionLabel="name"
                    :filter="true" placeholder="Select Month" />
                </div>
              </div>
              <div class="p-1 m-0 col-6">
                <span>Year</span>
                <div class="login-input">
                  <Dropdown v-model="form.year" :options="form.dropdownYears" optionValue="code" optionLabel="name"
                    :filter="true" placeholder="Select Year" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <template #footer>
      <Button label="Sign In" :loading="isLoading" icon="pi pi-check" @click="login" />
    </template>
  </Dialog>
  <BlockUI :blocked="blockedPanel">
    <div :class="containerClass" @click="onDocumentClick">
      <AppTopBar :topbarMenuActive="topbarMenuActive" :activeTopbarItem="activeTopbarItem" :inlineUser="inlineUser"
        @right-menubutton-click="onRightMenuButtonClick" @menubutton-click="onMenuButtonClick"
        @topbar-menubutton-click="onTopbarMenuButtonClick" @topbar-item-click="onTopbarItemClick"></AppTopBar>
      <transition name="layout-menu-container">
        <div class="layout-menu-container" @click="onMenuClick" v-show="isMenuVisible()">
          <div class="menu-scroll-content">
            <AppMenu :layoutMode="layoutMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive"
              @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
          </div>
        </div>
      </transition>
      <div class="layout-main">
        <Toast />
        <Message v-for="msg of messages" :life="5000" :sticky="false" :severity="msg.severity" :key="msg.content">{{
          msg.content }}</Message>
        <div class="layout-content">
          <router-view />
        </div>
        <AppFooter />
      </div>
      <div class="layout-content-mask"></div>
    </div>
  </BlockUI>
</template>
<script>
import BlockUI from 'primevue/blockui';
import Dialog from 'primevue/dialog';
import AppTopBar from '@/components/setup/AppTopbar.vue';
import AppMenu from '@/components/setup/AppMenu.vue';
import AppFooter from '@/components/setup/AppFooter.vue';
import EventBus from '@/setting/event-bus';
import { AuthControllers } from '@/controllers/auth/AuthControllers';
import { MasterControllers } from '@/controllers/master/MasterControllers';
import { themeColors } from '@/setting/themeColors';
import { topBarColors } from '@/setting/topBarColors';

import { getParam } from '@/utils/function';
import { setOauth } from '@/utils/cookies';
import { store } from '@/store';
import { dataMonths, dataYears } from '@/setting/data';

export default {
  data: () => ({
    auth: new AuthControllers(),
    master: new MasterControllers(),
    isLoading: false,
    titleLoginPanel: '',
    blockLoginPanel: store.state.blockedPanel,
    blockedPanel: store.state.blockedPanel,
    messages: [],
    count: 0,
    form: {
      branch: store.state.branch,
      username: '',
      password: '',
      month: null,
      dropdownMonths: null,
      year: null,
      dropdownYears: null,
    },
    layoutMode: 'static',
    lightMenu: true,
    overlayMenuActive: false,
    staticMenuMobileActive: true,
    staticMenuDesktopInactive: false,
    isRTL: false,
    inlineUser: false,
    topbarMenuActive: false,
    activeTopbarItem: null,
    rightPanelMenuActive: null,
    inlineUserMenuActive: false,
    menuActive: false,
    topbarColor: 'layout-topbar-light',
    topbarColors: topBarColors,
    theme: 'blue',
    themeColors: themeColors,
    menu: [],
  }),
  watch: {
    $route() {
      this.blockLoginPanel = store.state.blockedPanel;
      this.blockedPanel = store.state.blockedPanel;
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  created() {
    if (store.state.branch == null || store.state.branch == '' || store.state.periodMonth == null || store.state.periodMonth == '' || store.state.periodYear == null || store.state.periodYear == '') {
      this.$router.push({ path: '/login' });
    }
    if (getParam('token')) {
      setOauth(getParam('token'));
    }
  },
  mounted() {
    EventBus.on('reload-app', () => {
      window.location.reload();
    });
    EventBus.on('show-locked-panel', (status) => {
      this.blockedPanel = status;
    });
    EventBus.on('show-locked-panel-login', (message) => {
      store.dispatch('storeBlock', true);
      this.titleLoginPanel = message;
      this.lockedPanelLogin(message);
    });
    EventBus.on('show-warn-notif', (message) => {
      this.$toast.add({ severity: 'warn', detail: message, life: 5000 });
    });
    this.getAccount();
  },
  methods: {
    lockedPanelLogin(message) {
      this.blockLoginPanel = store.state.blockedPanel;
      this.blockedPanel = store.state.blockedPanel;
      if (!message) {
        this.$toast.add({ severity: 'warn', summary: message, life: 3000 });
      }
      return store.state.blockedPanel;
    },
    authorization(branch, username, password, month, year) {
      return this.auth.authorization(branch, username, password, month, year);
    },
    async getAccount() {
      await this.$router.isReady();
      if (this.$router.currentRoute.value.fullPath != '/login') {
        await this.auth.account();
        if (!store.state.isAuthenticated) {
          store.dispatch('storeBlock', true);
          this.blockLoginPanel = store.state.blockedPanel;
          this.blockedPanel = store.state.blockedPanel;
          this.titleLoginPanel = 'Session Expired';
        } else {
          let branch = await this.auth.loadBranch();
          store.dispatch('storeListBranch', branch);

          let division = await this.master.searchMaster('division/user', { code: store.state.profile.username.toUpperCase() });
          store.dispatch('storeListDivision', division);

          store.dispatch('storeBlock', false);
          this.blockLoginPanel = store.state.blockedPanel;
          this.blockedPanel = store.state.blockedPanel;
          EventBus.emit('show-menu-panel');
        }

        this.form.month = store.state.periodMonth;
        this.form.dropdownMonths = dataMonths;
        this.form.year = store.state.periodYear;
        this.form.dropdownYears = dataYears;
      }
    },
    async login() {
      this.isLoading = true;
      await this.authorization(this.form.branch, this.form.username, this.form.password, this.form.month, this.form.year);
      if (!store.state.blockedPanel) {
        await this.getAccount();
        this.$toast.add({ severity: 'success', summary: 'Welcome back ' + store.state.profile.fullName, life: 3000 });
      }
      this.isLoading = false;
    },
    onDocumentClick() {
      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.rightMenuClick) {
        this.rightPanelMenuActive = false;
      }

      if (!this.userMenuClick && this.isSlim() && !this.isMobile()) {
        this.inlineUserMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isHorizontal() || this.isSlim()) {
          this.menuActive = false;
        }

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
          this.hideOverlayMenu();
        }

        EventBus.emit('reset-active-index');
        this.unblockBodyScroll();
      }

      if (this.userMenuClick) {
        this.menuActive = false;
        EventBus.emit('reset-active-index');
      }

      this.topbarItemClick = false;
      this.menuClick = false;
      this.rightMenuClick = false;
      this.userMenuClick = false;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;
      this.topbarMenuActive = false;
      this.rightPanelMenuActive = false;

      if (this.layoutMode === 'overlay') {
        this.overlayMenuActive = !this.overlayMenuActive;
      }

      if (this.isDesktop()) this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
        if (this.staticMenuMobileActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      }

      event.preventDefault();
    },
    onTopbarMenuButtonClick(event) {
      this.topbarItemClick = true;
      this.topbarMenuActive = !this.topbarMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onTopbarItemClick(event) {
      this.topbarItemClick = true;

      if (this.activeTopbarItem === event.item) this.activeTopbarItem = null;
      else this.activeTopbarItem = event.item;

      event.originalEvent.preventDefault();
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onInlineUserClick() {
      this.userMenuClick = true;
      this.inlineUserMenuActive = !this.inlineUserMenuActive;
    },
    blockBodyScroll() {
      this.addClass(document.body, 'blocked-scroll');
    },
    unblockBodyScroll() {
      this.removeClass(document.body, 'blocked-scroll');
    },
    onRightMenuButtonClick(event) {
      this.rightMenuClick = true;
      this.rightPanelMenuActive = !this.rightPanelMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },
    onRightMenuClick() {
      this.rightMenuClick = true;
    },
    hideOverlayMenu() {
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        EventBus.emit('reset-active-index');
        this.hideOverlayMenu();
      }
      if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
        this.menuActive = false;
      }
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    isTablet() {
      let width = window.innerWidth;
      return width <= 1024 && width > 640;
    },
    isDesktop() {
      return window.innerWidth > 896;
    },
    isMobile() {
      return window.innerWidth <= 1025;
    },
    isHorizontal() {
      return this.layoutMode === 'horizontal';
    },
    isSlim() {
      return this.layoutMode === 'slim';
    },
    isMenuVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuDesktopInactive;
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
        else return true;
      } else {
        this.menuClick = false;
        return true;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
      this.staticMenuDesktopInactive = false;
      this.overlayMenuActive = false;

      if (this.isSlim() || this.isHorizontal()) {
        this.inlineUser = false;
        this.inlineUserMenuActive = false;
      }
    },
    onMenuColorChange(menuColor) {
      this.lightMenu = menuColor;
    },
    onProfileModeChange(profileMode) {
      this.inlineUser = profileMode;
    },
    onChangeOrientation(orientation) {
      this.isRTL = orientation;
    },
    onTopbarColorChange(topbarColor, logo) {
      this.topbarColor = topbarColor;
      const topbarLogoLink = document.getElementById('topbar-logo');
      topbarLogoLink.src = 'layout/images/' + logo + '.svg';
    },
    onThemeChange(theme) {
      this.theme = theme;
      this.changeStyleSheetUrl('layout-css', theme, 'layout');
      this.changeStyleSheetUrl('theme-css', theme, 'theme');
    },
    changeStyleSheetUrl(id, value, prefix) {
      let element = document.getElementById(id);
      let urlTokens = element.getAttribute('href').split('/');
      urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
      let newURL = urlTokens.join('/');

      this.replaceLink(element, newURL);
    },
    replaceLink(linkElement, href) {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-horizontal': this.layoutMode === 'horizontal',
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-slim': this.layoutMode === 'slim',
          'layout-menu-light': this.lightMenu === true,
          'layout-menu-dark': this.lightMenu === false,
          'layout-overlay-active': this.overlayMenuActive,
          'layout-mobile-active': this.staticMenuMobileActive,
          'layout-static-inactive': this.staticMenuDesktopInactive,
          'layout-rtl': this.isRTL,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
        this.topbarColor,
      ];
    },
  },
  components: {
    Dialog: Dialog,
    BlockUI: BlockUI,
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
};
</script>
