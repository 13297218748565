export const topBarColors = [
  {name: "Light", topbarColor: "layout-topbar-light", logo: "inkombizz", color: "#ffffff"},
  {name: "Dark", topbarColor: "layout-topbar-dark", logo: "inkombizz", color: "#252529"},
  {name: "Blue", topbarColor: "layout-topbar-blue", logo: "inkombizz", color: "#0772B3"},
  {name: "Green", topbarColor: "layout-topbar-green", logo: "inkombizz", color: "#0F8C50"},
  {name: "Orange", topbarColor: "layout-topbar-orange", logo: "inkombizz", color: "#C76D09"},
  {name: "Magenta", topbarColor: "layout-topbar-magenta", logo: "inkombizz", color: "#972BB1"},
  {name: "Blue Grey", topbarColor: "layout-topbar-bluegrey", logo: "inkombizz", color: "#406E7E"},
  {name: "Deep Purple", topbarColor: "layout-topbar-deeppurple", logo: "inkombizz", color: "#543CD9"},
  {name: "Brown", topbarColor: "layout-topbar-brown", logo: "inkombizz", color: "#794F36"},
  {name: "Lime", topbarColor: "layout-topbar-lime", logo: "inkombizz", color: "#849201"},
  {name: "Rose", topbarColor: "layout-topbar-rose", logo: "inkombizz", color: "#8F3939"},
  {name: "Cyan", topbarColor: "layout-topbar-cyan", logo: "inkombizz", color: "#0C8990"},
  {name: "Teal", topbarColor: "layout-topbar-teal", logo: "inkombizz", color: "#337E59"},
  {name: "Deep Orange", topbarColor: "layout-topbar-deeporange", logo: "inkombizz", color: "#D74A1D"},
  {name: "Indigo", topbarColor: "layout-topbar-indigo", logo: "inkombizz", color: "#3D53C9"},
  {name: "Pink", topbarColor: "layout-topbar-pink", logo: "inkombizz", color: "#BF275B"},
  {name: "Purple", topbarColor: "layout-topbar-purple", logo: "inkombizz", color: "#7F32DA"}
]
