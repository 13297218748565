import { authRequest, accountRequest, loadMenuRequest, loadBranchRequest, checkUserRequest } from '@/api/request/auth/AuthRequest';
import { setAuthentication, setPeriodBranch, setPeriodMonth, setPeriodYear, setSetup  } from '@/utils/cookies';
import { store, checkErrorResponse } from '@/store/index.js';

export class AuthControllers {
  authorization(branch, username, password, month, year) {
    let resp = null;
    resp = authRequest(branch, username, password)
      .then((response) => {
        this.setLoginDetail(response.data,branch, month, year);
        store.dispatch('storeAuth', true);
        store.dispatch('storeBlock', false);
      })
      .catch((err) => {
        store.dispatch('storeAuth', false);
        store.dispatch('storeBlock', true);
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }

  account() {
    let resp = null;
    resp = accountRequest()
      .then((response) => {
        this.setSessionSetup(response.data.data);
        store.dispatch('storeAuth', true);
        store.dispatch('storeBlock', false);
      })
      .catch((err) => {
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }

  async loadMenu() {
    let resp = null;
    resp = await loadMenuRequest()
      .then((response) => {
        return response.data?.data;
      })
      .catch((err) => {
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }

  async loadBranch() {
    let resp = null;
    resp = await loadBranchRequest()
      .then((response) => {
        localStorage["localBranchList"]= JSON.stringify(response?.data);
        return response?.data;
      })
      .catch((err) => {
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }

  async checkUser(user) {
    let resp = null;
    resp = await checkUserRequest(user)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => {
        checkErrorResponse(err);
      })
      .finally(() => {});
    return resp;
  }

  setLoginDetail(token, branch, month, year) {
    setAuthentication(token, month, year);
    store.dispatch('storeToken', token);
    setPeriodBranch(branch);
    store.dispatch('storeBranch', branch);
    setPeriodMonth(month);
    store.dispatch('storePeriodMonth', month);
    setPeriodYear(year);
    store.dispatch('storePeriodYear', year);
  }

  setSessionSetup(setup) {
    if (setup) {
      setSetup(setup);
      store.dispatch('storeSetup', setup);
      console.log(store.state)
    }
  }
}
