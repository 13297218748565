const sales = [
	//GROUP SALES
	{
		path: '/sales/sales-closing-doc',
		name: 'sales closing doc',
		component: () => import('@/views/sales/sales-closing-doc'),
	},
	{
		path: '/sales/sales-closing-doc/form',
		name: 'sales closing doc form',
		component: () => import('@/views/sales/sales-closing-doc/form'),
	},
	{
		path: '/sales/point-of-sales',
		name: 'sales point of sales',
		component: () => import('@/views/sales/point-of-sales'),
	},
	{
		path: '/sales/point-of-sales/form',
		name: 'sales point of sales form',
		component: () => import('@/views/sales/point-of-sales/form'),
	},
	{
		path: '/sales/sales-order',
		name: 'sales sales order',
		component: () => import('@/views/sales/sales-order'),
	},
	{
		path: '/warehouse/sales-order-view',
		name: 'sales order view',
		component: () => import('@/views/sales/sales-order-view'),
	},
	{
		path: '/sales/sales-order-comission',
		name: 'sales sales order comission',
		component: () => import('@/views/sales/sales-order-comission'),
	},
	{
		path: '/sales/sales-order-recalculating',
		name: 'sales sales order recalculating',
		component: () => import('@/views/sales/sales-order-recalculating'),
	},
	{
		path: '/sales/sales-order-closing',
		name: 'sales sales order closing',
		component: () => import('@/views/sales/sales-order-closing'),
	},
	{
		path: '/sales/clinic-admin',
		name: 'sales clinic-admin',
		component: () => import('@/views/sales/clinic/admin'),
	},
	{
		path: '/sales/clinic-reservation',
		name: 'sales clinic-reservation',
		component: () => import('@/views/sales/clinic'),
	},
	{
		path: '/sales/clinic-reservation/form',
		name: 'sales clinic-reservation form',
		component: () => import('@/views/sales/clinic/form'),
	},
	// {
	// 	path: '/sales/sales-order-approval',
	// 	name: 'sales sales order approval',
	// 	component: () => import('@/views/sales/sales-order-approval'),
	// },
	// {
	// 	path: '/sales/sales-order-approval/form',
	// 	name: 'sales sales order approval form',
	// 	component: () => import('@/views/sales/sales-order-approval/form'),
	// },
	// {
	// 	path: '/sales/sales-order-closing',
	// 	name: 'sales sales order closing',
	// 	component: () => import('@/views/sales/sales-order-closing'),
	// },
	// {
	// 	path: '/sales/sales-order-closing/form',
	// 	name: 'sales sales order closing form',
	// 	component: () => import('@/views/sales/sales-order-closing/form'),
	// },
	// {
	// 	path: '/sales/picking-list-sales-order',
	// 	name: 'sales picking list sales order',
	// 	component: () => import('@/views/sales/picking-list-sales-order'),
	// },
	// {
	// 	path: '/sales/picking-list-sales-order/form',
	// 	name: 'sales picking list sales order form',
	// 	component: () => import('@/views/sales/picking-list-sales-order/form'),
	// },
	// {
	// 	path: '/sales/picking-list-sales-order-confirmation',
	// 	name: 'sales picking list sales order confirmation',
	// 	component: () => import('@/views/sales/picking-confirmation'),
	// },
	// {
	// 	path: '/sales/picking-list-sales-order-confirmation/form',
	// 	name: 'sales picking list sales order confirmation form',
	// 	component: () => import('@/views/sales/picking-confirmation/form'),
	// },
	// {
	// 	path: '/sales/un-assign-delivery-note-sales-order',
	// 	name: 'sales unassign delivery note sales order',
	// 	component: () => import('@/views/sales/un-assign-delivery-note-sales-order'),
	// },
	// {
	// 	path: '/sales/un-assign-delivery-note-sales-order/form',
	// 	name: 'sales unassign delivery note sales order form',
	// 	component: () => import('@/views/sales/un-assign-delivery-note-sales-order/form'),
	// },
	// {
	// 	path: '/sales/customer-invoice-sales-order',
	// 	name: 'sales customer invoice sales order',
	// 	component: () => import('@/views/sales/customer-invoice-sales-order'),
	// },
	// {
	// 	path: '/sales/customer-invoice-sales-order/form',
	// 	name: 'sales customer invoice sales order form',
	// 	component: () => import('@/views/sales/customer-invoice-sales-order/form'),
	// },
	// {
	// 	path: '/sales/sales-return-by-invoice',
	// 	name: 'sales sales return',
	// 	component: () => import('@/views/sales/sales-return'),
	// },
	// {
	// 	path: '/sales/sales-return-by-invoice/form',
	// 	name: 'sales sales-return form',
	// 	component: () => import('@/views/sales/sales-return/form'),
	// },
	// {
	// 	path: '/sales/print-multiple-delivery-note',
	// 	name: 'sales print multiple delivery note',
	// 	component: () => import('@/views/sales/print-multiple-delivery-note'),
	// },
	// {
	// 	path: '/sales/print-multiple-customer-invoice',
	// 	name: 'sales print multiple customer invoice',
	// 	component: () => import('@/views/sales/print-multiple-customer-invoice'),
	// },
	// {
	// 	path: '/sales/order-material-form',
	// 	name: 'sales order material form',
	// 	component: () => import('@/views/sales/order-material-form'),
	// },
	// {
	// 	path: '/sales/order-material-form/form',
	// 	name: 'sales order material form form',
	// 	component: () => import('@/views/sales/order-material-form/form'),
	// },
	// {
	// 	path: '/sales/order-material-form-inquiry',
	// 	name: 'sales order material form inquiry',
	// 	component: () => import('@/views/sales/order-material-form-inquiry'),
	// },
	// {
	// 	path: '/sales/order-material-form-closing',
	// 	name: 'sales order material form closing',
	// 	component: () => import('@/views/sales/order-material-form-closing'),
	// },
	// {
	// 	path: '/sales/order-material-form-closing/form',
	// 	name: 'sales order material closing form',
	// 	component: () => import('@/views/sales/order-material-form-closing/form'),
	// },
	// {
	// 	path: '/sales/order-material-form-return',
	// 	name: 'sales order material form return',
	// 	component: () => import('@/views/sales/order-material-form-return'),
	// },
	// {
	// 	path: '/sales/order-material-form-return/form',
	// 	name: 'sales order material return form',
	// 	component: () => import('@/views/sales/order-material-form-return/form'),
	// },
	// {
	// 	path: '/sales/sales-history',
	// 	name: 'sales sales history',
	// 	component: () => import('@/views/sales/sales-history'),
	// },
];

export default sales;
